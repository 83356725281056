import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_AVAILABLE_APPOINTMENT,
    GET_ALL_APPOINTMENTS_AUCTION,
    GET_ALL_APPOINTMENTS_USER,
    GET_INVOICE_APPOINTMENT,
    GET_APPOINTMENT_DETAIL_USER,
} from './appointmentTypes'
export default (state, action) => {
    switch (action.type) {
        case GET_AVAILABLE_APPOINTMENT:
            return {
                ...state,
                available_appointments: action.payload,
            }
        case GET_ALL_APPOINTMENTS_AUCTION:
            return {
                ...state,
                all_appointments_auction: action.payload,
            }
        case GET_ALL_APPOINTMENTS_USER:
            return {
                ...state,
                all_appointments_user: action.payload,
            }
        case GET_APPOINTMENT_DETAIL_USER:
            return {
                ...state,
                appointment_detail_user: action.payload,
            }
        case GET_INVOICE_APPOINTMENT:
            return {
                ...state,
                invoice_appointment: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
