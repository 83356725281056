import {
    GET_ALL_AUCTIONS,
    GET_SINGLE_AUCTION,
    GET_AUCTION_PROJECTS,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './auctionTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_AUCTIONS:
            return {
                ...state,
                all_auctions: action.payload,
            }
        case GET_SINGLE_AUCTION:
            return {
                ...state,
                single_auction: action.payload,
            }
        case GET_AUCTION_PROJECTS:
            return {
                ...state,
                auction_projects: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
