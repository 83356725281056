import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormHelperText from '@material-ui/core/FormHelperText'
import { makeStyles } from '@material-ui/core/styles'
import { ListItem } from '@material-ui/core'
import '../../../common/productCommon.css'

const GreenCheckbox = withStyles({
    root: {
        color: 'var(--primColor)',
        '&$checked': {
            color: 'var(--primColor)',
        },
        MuiFormControlLabelRoot: {
            marginBottom: 0,
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />)

function StyledCheckbox(props) {
    return (
        <Checkbox
            disableRipple
            color="default"
            checkedIcon={<span className="customizedIcon checkedIcon" />}
            icon={<span className="customizedIcon" />}
            inputProps={{ 'aria-label': 'decorative checkbox' }}
            id={props.id}
            {...props}
        />
    )
}

const CheckBox = (props) => {
    return (
        <>
            {props.customized ? (
                <div className="customCheckbox">
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <StyledCheckbox
                                    name={props.name}
                                    checked={props.checked}
                                    value={props.value}
                                    disabled={props.disabled || false}
                                    onChange={props.onChange ? (e) => props.onChange(e) : null}
                                    id={
                                        props.label
                                            ? props.label
                                            : props.name
                                            ? props.name
                                            : 'check_box'
                                    }
                                />
                            }
                            label={props.label ? <ListItem button>{props.label}</ListItem> : null}
                        />
                    </FormGroup>
                    <FormHelperText>{props.error}</FormHelperText>
                </div>
            ) : (
                <div className="customCheckbox">
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <GreenCheckbox
                                    name={props.name}
                                    checked={props.checked}
                                    value={props.value}
                                    disabled={props.disabled || false}
                                    onChange={props.onChange ? (e) => props.onChange(e) : null}
                                    id={
                                        props.label
                                            ? props.label
                                            : props.name
                                            ? props.name
                                            : 'check_box'
                                    }
                                />
                            }
                            label={props.label ? <ListItem button>{props.label}</ListItem> : null}
                        />
                    </FormGroup>
                    <FormHelperText>{props.error}</FormHelperText>
                </div>
            )}
        </>
    )
}

export default CheckBox
