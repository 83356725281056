import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import InputMask from 'react-input-mask'

const CustomMaskInput = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    const [state, setPreValue] = useState(props.value)
    const [maskChar, setMaskChar] = useState('_')
    const [onLoad, setOnLoad] = useState(true)
    let [passwordShown, setPasswordShown] = useState(false)

    const [ppLoad, setppLoad] = useState(false)

    const togglePasswordVisiblity = () => {
        setMaskChar(passwordShown ? '_' : null)
        setPasswordShown(passwordShown ? false : true)
    }

    const togglePassword = () => {
        setPasswordShown(!passwordShown)
    }

    useEffect(() => {
        if (!ppLoad && props.modal) {
            setPasswordShown(true)
            setTimeout(() => {
                setPasswordShown(false)
            }, 500)

            setppLoad(true)
        }
    })

    const removeHidden = (maskedValue, orgValue) => {
        if (maskedValue.includes('x')) {
            let temp = orgValue
            maskedValue.split('').forEach((x, index) => {
                if (x !== 'x') {
                    ;[temp.slice(0, index), '-', temp.slice(index)].join('')
                }
            })
            return temp
        } else return maskedValue
    }
    const beforeMaskedValueChanges = (newState, oldState, i, j) => {
        let { value, selection } = newState
        let { value: value2 } = oldState
        if (value === '' && onLoad) {
            value = props.value
            setPreValue(props.value)
            setOnLoad(false)
        }
        value = !passwordShown ? value && value.replace(/[0-9]/g, 'x') : removeHidden(value, state)
        if (
            selection &&
            typeof selection.start === 'number' &&
            selection.start > -1 &&
            (value != value2 || selection?.start === 0) &&
            state.length <= 9 &&
            !onLoad
        ) {
            if (i != '' && i != null && typeof i == 'string') {
                setPreValue(
                    [state.slice(0, selection.start), i, state.slice(selection.end)].join(''),
                )
            }
            if (selection && selection.start > -1 && typeof i != 'string' && i == null) {
                let lcf = selection.start > 3 ? (selection.start > 6 ? 2 : 1) : 0
                setPreValue(
                    [
                        state.slice(0, selection.start - lcf),
                        state.slice(selection.start - lcf + 1),
                    ].join(''),
                )
            }
        }
        return {
            value,
            selection,
        }
    }

    useEffect(() => {
        if (state !== props.value)
            props.onChange({
                target: { value: state, name: props.name },
            })
    }, [state])

    const onchangeHandle = (val) => {
        if (val.target.value === '' && !onLoad) setPreValue('')
    }

    return (
        <div className="customInput" style={props.inputStyle}>
            {props.upperLabel ? (
                <>
                    <label>{props.label}</label>
                    <InputMask
                        maskChar={maskChar}
                        mask={props.maskValue}
                        beforeMaskedValueChange={beforeMaskedValueChanges}
                        onChange={onchangeHandle}
                        disabled={props.disabled}
                    >
                        {(inputProps) => (
                            <TextField
                                // {...inputProps}
                                autoFocus={props.autoFocus}
                                name={props.name}
                                InputProps={{
                                    // inputProps: props.inputProps,
                                    startAdornment: props.startAdornment,
                                    // endAdornment: props.endAdornment,
                                    endAdornment: passwordShown ? (
                                        <span
                                            className="material-icons cursorPointer"
                                            onClick={togglePasswordVisiblity}
                                        >
                                            visibility_off
                                        </span>
                                    ) : (
                                        <span
                                            className="material-icons cursorPointer"
                                            onClick={togglePasswordVisiblity}
                                        >
                                            visibility
                                        </span>
                                    ),
                                    shrink: shrink,
                                }}
                                id={props.id}
                                type={props.type}
                                size={props.size}
                                disabled={props.disabled}
                                variant={props.variant ? props.variant : 'outlined'}
                                placeholder={props.placeholder}
                                error={props.error}
                                helperText={props.helperText}
                            />
                        )}
                    </InputMask>
                </>
            ) : props.last4Masked ? (
                <>
                    <Tooltip
                        title={props.tooltiptitle}
                        arrow={props.tooltiptitle ? true : false}
                        placement="top"
                        disableHoverListener={props.disableHoverListener}
                        disableFocusListener={props.tooltiptitle ? false : true}
                    >
                        <TextField
                            value={
                                passwordShown
                                    ? props?.value
                                    : props?.value?.slice(0, -4).replace(/./g, '*') +
                                      props?.value?.slice(-4)
                            }
                            autoFocus={props.autoFocus}
                            name={props.name}
                            onChange={props.onChange}
                            onBlur={props.onBlur}
                            InputProps={{
                                inputProps: props.inputProps,
                                startAdornment: props.startAdornment,
                                endAdornment: passwordShown ? (
                                    <span
                                        className="material-icons cursorPointer"
                                        onClick={togglePassword}
                                    >
                                        {props.customEndAdornmentOn || 'visibility_off'}
                                    </span>
                                ) : (
                                    <span
                                        className="material-icons cursorPointer"
                                        onClick={togglePassword}
                                    >
                                        {props.customEndAdornmentOff || 'visibility'}
                                    </span>
                                ),
                                shrink: shrink,
                                'aria-label': props.label,
                            }}
                            InputLabelProps={{
                                id: props.id,
                                name: props.label,
                                'aria-label': props.label,
                                'aria-labelledby': props.id,
                            }}
                            htmlFor={props.id}
                            id={props.id}
                            label={props.label}
                            type={props.type}
                            size={props.size}
                            disabled={!passwordShown ? true : props.disabled}
                            variant={props.variant ? props.variant : 'outlined'}
                            onKeyDown={props.onKeyDown}
                            placeholder={props.placeholder}
                            error={props.error}
                            helperText={props.helperText}
                        />
                    </Tooltip>
                </>
            ) : (
                <Tooltip
                    title={props.tooltiptitle}
                    arrow={props.tooltiptitle ? true : false}
                    placement="top"
                    disableHoverListener={
                        props.disableHoverListener ? props.disableHoverListener : true
                    }
                    disableFocusListener={props.tooltiptitle ? false : true}
                >
                    <InputMask mask={props.maskValue} value={props.value} onChange={props.onChange}>
                        {(inputProps) => (
                            <TextField
                                {...inputProps}
                                autoFocus={props.autoFocus}
                                name={props.name}
                                InputProps={{
                                    inputProps: props.inputProps,
                                    startAdornment: props.startAdornment,
                                    endAdornment: props.endAdornment,
                                    shrink: shrink,
                                }}
                                id={props.id}
                                label={props.label}
                                type={props.type}
                                size={props.size}
                                disabled={props.disabled}
                                variant={props.variant ? props.variant : 'outlined'}
                                placeholder={props.placeholder}
                                error={props.error}
                                helperText={props.helperText}
                            />
                        )}
                    </InputMask>
                </Tooltip>
            )}
        </div>
    )
}

export default CustomMaskInput
