import React, { useState, useContext, useEffect, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Button, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import './ProductView.css'
import Buynow from '@/custom/components/molecules/Buynow/BuynowItem'
import CustomCommonContext from '@/custom/context/common/commonContext'
import ProductCommonContext from '@/product/context/common/commonContext'
import { dateFormatFront, currencyFormat, floatFormat } from '@/custom/common/components'
// import TertiaryButton from '@/custom/components/atoms/TertiaryButton'
import ReverseBidding from '@/custom/components/molecules/Reverse/Bidding/BiddingItem'
import Timer from '@/product/common/timer'
import Bidding from '@/custom/components/molecules/Bidding/BiddingItem'
import SealedBidding from '@/custom/components/molecules/Bidding/SealedItem'
import ProductContext from '@/product/context/product/productContext'
import AuthContext from '@/product/context/auth/authContext'
import Loaders from '@/custom/components/molecules/Loaders'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import { handleRedirectInternal, checkProductOpen } from '@/product/common/components'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import SEO from '@/utils/SEO'
import DynamicView from '@/product/components/species/dynamic/common/Product/ProductDynamic'
import FavouriteCheckbox from '@/product/components/atoms/FavoriteCheckbox'
import ReactImageMagnify from 'react-image-magnify'
import Slider from 'react-slick'

const ProductView = (props) => {
    const { t } = useTranslation()

    const [expanded, setExpanded] = useState()
    const [images, setImages] = useState([])
    let [viewProduct, setViewProduct] = useState([])
    const [product, setProduct] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const productContext = useContext(ProductContext)
    const productCommonContext = useContext(ProductCommonContext)

    const { user, isAuthenticated } = authContext
    const { search_allsimilar, getAllSimilarProducts } = productContext
    const customCommonContext = useContext(CustomCommonContext)
    const { configVariables } = customCommonContext
    const { setBidHistoryValue } = productCommonContext

    const updateLoading = (data) => {
        setIsLoading(data)
    }

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        setIsLoading(true)

        if (product) {
            const search = {
                limit: 3,
                page: 1,
                orderby: 'p.date_closed,asc',
                order: '',
                similars: {
                    category: {
                        value: [product.categoryTypeId],
                        type: 'array',
                        field: 'it.categoryTypeId',
                    },
                    condition: {
                        value: [product.conditionTypeId],
                        type: 'array',
                        field: 'it.conditionTypeId',
                    },
                    location: {
                        value: [product.location_id],
                        type: 'array',
                        field: 'p.location_id',
                    },
                    auctionid: {
                        value: [product.auctionid],
                        type: 'array',
                        field: 'p.auctionid',
                    },
                },
                filters: {
                    product_id: {
                        value: product.id,
                        type: 'notin',
                        field: 'p.id',
                    },
                },
            }
            getAllSimilarProducts(search)
        }
    }, [product ? product.id : 0])

    useEffect(() => {
        setIsLoading(false)
        setViewProduct(search_allsimilar.records.length ? search_allsimilar.records : [])
    }, [search_allsimilar])

    useEffect(() => {
        setViewProduct([])
        return () => {
            setViewProduct([])
        }
    }, [])

    const [nav1, setNav1] = useState()
    const [nav2, setNav2] = useState()

    useEffect(() => {
        let noImageArray = [
            {
                compressed: '/assets/images/noImage.png',
                original: '/assets/images/noImage.png',
                thumbnail: '/assets/images/noImage.png',
            },
        ]
        if (product) {
            if (product.allattachmentlist) {
                if (product.allattachmentlist.length) {
                    product.allattachmentlist.forEach(function (data) {
                        data['original'] = global.images_url + data['file_name']
                        data['compressed'] = global.images_url + 'compressed/' + data['file_name']
                        data['thumbnail'] = global.images_url + 'thumbnail/' + data['file_name']
                        data['renderItem'] = RenderImageZoom.bind(this)
                    })
                    setImages(product.allattachmentlist)
                } else {
                    setImages(noImageArray)
                }
            } else {
                setImages(noImageArray)
            }
        } else {
            setImages(noImageArray)
        }
    }, [product])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }

    const thumbnailSettings = {
        infinite: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        arrows: true,
        swipeToSlide: true,
        lazyLoad: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 5,
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 4,
                },
            },
        ],
    }

    const RenderImageZoom = (item) => {
        return (
            <ReactImageMagnify
                {...{
                    smallImage: {
                        alt: '',
                        isFluidWidth: true,
                        src: item.compressed,
                        enlargedImageClassName: 'enlargedImage',
                    },
                    largeImage: {
                        src: item.original,
                        width: 1200,
                        height: 1800,
                    },
                    enlargedImagePosition: 'over',
                    isHintEnabled: true,
                    // enlargedImageContainerClassName: 'customZoom',
                    enlargedImageStyle: { objectFit: 'contain' },
                }}
            />
        )
    }

    const dateAddedReached = (data) => {
        if (product.future_active) {
            if (data) {
                setProduct({ ...product, future_active: 0 })
            }
        }
    }

    const dateClosedReached = (data) => {
        if (product.past_inactive) {
            if (data) {
                setProduct({ ...product, past_inactive: 0 })
            }
        }
    }

    return (
        <>
            <SEO title="Auction View" url={window.location.href} />
            <div className="productView" id="productView">
                {isLoading ? (
                    <Loaders name="product_view" isLoading={isLoading} loop={1} />
                ) : product ? (
                    <div className={product.auctiontype === 'sealed' ? 'offerView' : ''}>
                        <Button className="closeSlider" onClick={props.drawerHandler}>
                            <span className="material-icons">close</span>
                        </Button>
                        {isAuthenticated && (
                            <div className="pvBidStatus">
                                {product.auctiontype !== 'sealed' ? (
                                    <>
                                        {product.bidtopstatus === 'outbid' && (
                                            <h4 className="outbid">You have been outbid.</h4>
                                        )}
                                        {product.bidtopstatus === 'winner' && (
                                            <h4 className="winning">You are winning this item!</h4>
                                        )}
                                        {product.bidtopstatus === 'won' && (
                                            <h4 className="won">
                                                Congratulations, you have won this item!
                                            </h4>
                                        )}
                                        {product.bidtopstatus === 'lost' && (
                                            <h4 className="lost">
                                                Unfortunately you have lost this item.
                                            </h4>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {product.bid_or_not ? (
                                            <>
                                                {product.market_status === 'open' ? (
                                                    <>
                                                        {product.past_inactive ? (
                                                            <>
                                                                {/* <h4
                                                                 className="productWinningStatus winning"
                                                                 id={'offer_active'}
                                                             >
                                                                 {'Active'}
                                                             </h4>  */}
                                                            </>
                                                        ) : (
                                                            <h4
                                                                className="productWinningStatus outbid"
                                                                id={'offer_pending'}
                                                            >
                                                                {'Pending'}
                                                            </h4>
                                                        )}
                                                    </>
                                                ) : null}

                                                {product.market_status === 'sold' ? (
                                                    <>
                                                        {parseInt(product.buynowed, 10) ===
                                                        parseInt(user?.id, 10) ? (
                                                            <h4
                                                                className="productWinningStatus won"
                                                                id="offer_won"
                                                            >
                                                                {'Won'}
                                                            </h4>
                                                        ) : null}
                                                        {parseInt(product.buynowed, 10) !==
                                                        parseInt(user?.id, 10) ? (
                                                            <h4
                                                                className="productWinningStatus lost"
                                                                id="offer_lost"
                                                            >
                                                                {'Lost'}
                                                            </h4>
                                                        ) : null}
                                                    </>
                                                ) : null}
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </div>
                        )}

                        <div className="d-flex flex-wrap">
                            {/* {isAuthenticated ? (
                                    <>
                                        <div className="pvBidBox">
                                            {product.market_status === 'open' &&
                                            !product.future_active ? (
                                                <>
                                                    {product.auction ? (
                                                        <>
                                                            <Bidding data={product} type="proxy" />
                                                            <Bidding data={product} type="hard" />
                                                        </>
                                                    ) : null}

                                                    {product.buynow &&
                                                    product.bprice >= product.wprice ? (
                                                        <Buynow
                                                            data={product}
                                                            editQuantity={true}
                                                            isOnlyBuynow={
                                                                product.auction === 0 ? true : false
                                                            }
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ) : null}
                                        </div>
                                        {product.bidtopstatus === 'winner' && (
                                            <div className="pvBidStatus">
                                                <h4 className="winning">
                                                    {'Yayy you are winning this item caps'}.
                                                </h4>
                                            </div>
                                        )}
                                        {product.bidtopstatus === 'won' && (
                                            <div className="pvBidStatus">
                                                <h4 className="won">
                                                    {'Congratulations you have won this item'}
                                                </h4>
                                            </div>
                                        )}
                                        {product.bidtopstatus === 'lost' && (
                                            <div className="pvBidStatus">
                                                <h4 className="lost">
                                                    {'Unfortunately you have lost this item'}
                                                </h4>
                                            </div>
                                        )}
                                    </>
                                )} */}
                            <div className="pvLt">
                                <Slider
                                    className="mainSlider"
                                    asNavFor={nav2}
                                    ref={(slider1) => setNav1(slider1)}
                                    arrows={false}
                                    lazyLoad={true}
                                    infinite={false}
                                    // beforeChange={(current, next) => {
                                    //     // document.body.classList.add('noScroll')
                                    //     setNextSlideIndex(next)
                                    // }}
                                >
                                    {images.map((data, index) => RenderImageZoom(data))}
                                </Slider>

                                <Slider
                                    {...thumbnailSettings}
                                    asNavFor={nav1}
                                    ref={(slider2) => setNav2(slider2)}
                                    className="sliderThumbnail"
                                >
                                    {images.map((data, index) => (
                                        <img src={data.thumbnail} />
                                    ))}
                                </Slider>
                                {/* <ImageGallery
                                    items={images}
                                    thumbnailPosition="bottom"
                                    showNav={false}
                                    onErrorImageURL="/assets/images/noImage.png"
                                    showBullets={false}
                                    showFullscreenButton={false}
                                    showPlayButton={false}
                                /> */}
                            </div>
                            <div className="pvRt">
                                <div>
                                    {console.log(product, 'productCheck')}
                                    <div className="pvProductInfo">
                                        <h5>{product?.auctionlot_title}</h5>
                                        <h2>{product.title}</h2>
                                    </div>
                                    {product.market_status === 'open' ? (
                                        <FavouriteCheckbox
                                            watchlisted={product.watchlistid}
                                            addLabel="Add to watchlist"
                                            removeLabel="Remove from watchlist"
                                            project_id={product.id}
                                        />
                                    ) : null}

                                    <div className="productSpecs">
                                        <div className="pvDescChild">
                                            <label>Lot number:</label>
                                            <h6>{product?.auction_lot}</h6>
                                        </div>
                                        <div className="pvDescChild">
                                            <label>Total weight:</label>
                                            <h6>
                                                {floatFormat(product?.weight)}&nbsp;
                                                {product?.weight > 1 ? 'cts' : 'ct'}
                                            </h6>
                                        </div>
                                        <div className="pvDescChild">
                                            <label>Stone count:</label>
                                            {product?.total_stones != 0 &&
                                            product?.total_stones !== null ? (
                                                <h6>{product?.total_stones}</h6>
                                            ) : (
                                                'N/A'
                                            )}
                                        </div>
                                        <div className="pvDescChild">
                                            <label>Avg size:</label>
                                            {product?.average_size !== 0 &&
                                            product?.average_size !== null ? (
                                                <h6>
                                                    {floatFormat(product?.average_size)}&nbsp;
                                                    {product?.average_size > 1 ? 'cts' : 'ct'}
                                                </h6>
                                            ) : (
                                                'N/A'
                                            )}
                                        </div>

                                        {/* <DynamicView
                                            product={product}
                                            ignoreArray={[
                                                'filters',
                                                'categories',
                                                'auction_cycle',
                                                'event_number',
                                                'featured',
                                                'inv_id',
                                            ]}
                                            updateLoading={updateLoading}
                                        /> */}
                                    </div>
                                    {product?.custom_bid_data?.length ? (
                                        <div className="pvDescChild d-flex align-items-center justify-content-start flex-wrap">
                                            <label className="mr-2">Your Initial Offer:</label>
                                            <h6>
                                                {currencyFormat(
                                                    product?.custom_bid_data[0]?.offer_bid,
                                                )}{' '}
                                                per carat{' '}
                                            </h6>
                                        </div>
                                    ) : null}

                                    <h6
                                        className="pvDesc"
                                        dangerouslySetInnerHTML={{
                                            __html: product.desc_proc,
                                        }}
                                    ></h6>
                                    {product.market_status === 'open' ? (
                                        <div className="pvTimerBox">
                                            <h4>
                                                <Timer
                                                    date_added={product.date_added}
                                                    date_closed={product.date_closed}
                                                    withText={1}
                                                    date_added_check={product.future_active}
                                                    date_added_reached={dateAddedReached}
                                                    date_closed_check={product.past_inactive}
                                                    date_closed_reached={dateClosedReached}
                                                    endText={'Time left' + ':'}
                                                    startText={'Starts in' + ':'}
                                                    closedText={
                                                        product.auctiontype === 'sealed'
                                                            ? 'Offer Closed'
                                                            : product.market_status === 'open'
                                                            ? 'Auction Closing'
                                                            : 'Auction Closed'
                                                    }
                                                ></Timer>
                                            </h4>
                                        </div>
                                    ) : (
                                        <div className="pvTimerBox">
                                            <span className="material-icons">timer</span>
                                            <label>{t('Closed on')}</label>
                                            <h4>{dateFormatFront(product.date_closed)}</h4>
                                        </div>
                                    )}

                                    <div className="pvActions">
                                        {isAuthenticated ? (
                                            <>
                                                {product.market_status === 'open' ? (
                                                    <div className="pvBidInfo">
                                                        {product.buynow &&
                                                        product.wprice < product.bprice ? (
                                                            <div className="pabChild">
                                                                <label>{t('Buy Now Price')}</label>
                                                                <h4>
                                                                    {currencyFormat(
                                                                        product.bprice *
                                                                            product.qty,
                                                                    )}
                                                                </h4>
                                                            </div>
                                                        ) : (
                                                            ''
                                                        )}

                                                        {product.auction ? (
                                                            <>
                                                                {product.auctiontype ===
                                                                'sealed' ? (
                                                                    <>
                                                                        {product.current_bid ? (
                                                                            <>
                                                                                <div className="pabChild pvCurrentBid activeBid">
                                                                                    <label>
                                                                                        {product.bid_or_not
                                                                                            ? 'My Current Offer'
                                                                                            : 'Starting Price'}
                                                                                        :
                                                                                    </label>
                                                                                    <h4>
                                                                                        {currencyFormat(
                                                                                            product.current_bid,
                                                                                        )}{' '}
                                                                                        per carat
                                                                                    </h4>
                                                                                </div>
                                                                                <Tooltip title="Total value = Carat Weight * Current Bid">
                                                                                    <div className="pabChild pvCurrentBid">
                                                                                        <label>
                                                                                            Total
                                                                                            Value:
                                                                                        </label>
                                                                                        <h4>
                                                                                            {currencyFormat(
                                                                                                parseFloat(
                                                                                                    product.current_bid,
                                                                                                ) *
                                                                                                    parseFloat(
                                                                                                        product?.weight,
                                                                                                    ),
                                                                                            )}
                                                                                        </h4>
                                                                                    </div>
                                                                                </Tooltip>
                                                                            </>
                                                                        ) : (
                                                                            <Tooltip title="Total value = Carat Weight * Current Bid">
                                                                                <div className="pabChild pvCurrentBid valueBeforeOffer">
                                                                                    <label>
                                                                                        Total Value:
                                                                                    </label>
                                                                                    <h4>-</h4>
                                                                                </div>
                                                                            </Tooltip>
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {product.cbidtext ==
                                                                        'Starting Price' ? (
                                                                            <div className="pabChild pvCurrentBid">
                                                                                <label>
                                                                                    {
                                                                                        product.cbidtext
                                                                                    }
                                                                                </label>
                                                                                <h4>
                                                                                    {product?.hide_start_price ? (
                                                                                        <span>
                                                                                            Hidden
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span>
                                                                                            {currencyFormat(
                                                                                                product.wprice,
                                                                                            )}{' '}
                                                                                            per
                                                                                            carat{' '}
                                                                                        </span>
                                                                                    )}
                                                                                </h4>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="pabChild pvCurrentBid">
                                                                                <label>
                                                                                    {
                                                                                        product.cbidtext
                                                                                    }
                                                                                </label>
                                                                                <h4>
                                                                                    <span>
                                                                                        {currencyFormat(
                                                                                            product.wprice,
                                                                                        )}{' '}
                                                                                        per carat{' '}
                                                                                    </span>
                                                                                </h4>
                                                                            </div>
                                                                        )}
                                                                        <Tooltip title="Total value = Carat Weight * Current Bid">
                                                                            <div className="pabChild pvCurrentBid">
                                                                                <label>
                                                                                    Total Value:
                                                                                </label>
                                                                                <h4>
                                                                                    {currencyFormat(
                                                                                        parseFloat(
                                                                                            product.wprice,
                                                                                        ) *
                                                                                            parseFloat(
                                                                                                product?.weight,
                                                                                            ),
                                                                                    )}
                                                                                </h4>
                                                                            </div>
                                                                        </Tooltip>{' '}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : null}
                                                    </div>
                                                ) : product.bid_or_not && product.buynowamount ? (
                                                    <>
                                                        {product.auctiontype === 'sealed' ? (
                                                            <>
                                                                {product.market_status ===
                                                                'sold' ? (
                                                                    <>
                                                                        {parseInt(
                                                                            product.buynowed,
                                                                            10,
                                                                        ) ===
                                                                        parseInt(user?.id, 10) ? (
                                                                            <>
                                                                                <div className="pabChild pvCurrentBid">
                                                                                    <label>
                                                                                        {
                                                                                            product.cbidtext
                                                                                        }
                                                                                    </label>
                                                                                    <h4>
                                                                                        {currencyFormat(
                                                                                            product.buynowamount,
                                                                                        )}
                                                                                    </h4>
                                                                                </div>
                                                                            </>
                                                                        ) : null}{' '}
                                                                    </>
                                                                ) : null}
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div className="pabChild pvCurrentBid">
                                                                    <label>
                                                                        {product.cbidtext}
                                                                    </label>
                                                                    <h4>
                                                                        {currencyFormat(
                                                                            product.buynowamount,
                                                                        )}
                                                                    </h4>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                ) : null}
                                            </>
                                        ) : null}
                                        <div className="pvBidBox">
                                            {product.market_status === 'open' &&
                                            !product.future_active ? (
                                                <>
                                                    {product.auction ? (
                                                        <>
                                                            {isAuthenticated ? (
                                                                <>
                                                                    {product.auctiontype ===
                                                                    'sealed' ? (
                                                                        <SealedBidding
                                                                            data={product}
                                                                            type={'sealed'}
                                                                        />
                                                                    ) : (
                                                                        <Bidding
                                                                            data={product}
                                                                            type={
                                                                                parseInt(
                                                                                    configVariables?.proxy_bidding_settings,
                                                                                ) === 0
                                                                                    ? 'hard'
                                                                                    : 'proxy'
                                                                            }
                                                                        />
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <PrimaryButton
                                                                    label={t('Login to bid')}
                                                                    btnSize="small"
                                                                    id="productview_logintobid"
                                                                    onClick={() =>
                                                                        handleRedirectInternal(
                                                                            history,
                                                                            'login',
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                        </>
                                                    ) : null}

                                                    {product.buynow &&
                                                    product.bprice >= product.wprice ? (
                                                        <Buynow
                                                            data={product}
                                                            editQuantity={true}
                                                            isOnlyBuynow={
                                                                product.auction === 0 ? true : false
                                                            }
                                                        />
                                                    ) : (
                                                        ''
                                                    )}
                                                </>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </>
    )
}
export default ProductView
