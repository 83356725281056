import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import openSocket from 'socket.io-client'
import { useFormik } from 'formik'
import { Button, MenuItem } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

import CustomSelect from '@/product/components/atoms/Inputs/CustomSelect'
import CustomInput from '@/product/components/atoms/Inputs/CustomInput'
import AuthContext from '@/product/context/auth/authContext'
import AlertContext from '@/product/context/alert/alertContext'
import AuctionCard from '@/custom/components/molecules/ProductCard/AuctionCard'
import OfferCard from '@/custom/components/molecules/ProductCard/OfferCard'
import AuctionContext from '@/product/context/auction/auctionContext'
import { messageHandler } from '@/product/common/socketHandler'
import { mapData, useCustomMediaQuery } from '@/product/common/components'

import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import Loaders from '@/custom/components/molecules/Loaders'
import UserContext from '@/product/context/user/userContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import './auction.css'

import SEO from '@/utils/SEO'
import { LocalLaundryService } from '@material-ui/icons'
import * as Yup from 'yup'
let randomVariable = Math.random()
const AuctionSearch = (props) => {
    const { search_allauctions, getAllAuctionProducts } = useContext(AuctionContext)
    const { user, loadUser, isAuthenticated } = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)

    const userContext = useContext(UserContext)
    const { setAlert } = useContext(AlertContext)
    const { updateProfile, updatePreference, responseStatus } = userContext

    const pageOptions = props.pageOptions
    const sortShow = props.sortShow
    const search = props.search

    let [auctions, setAuctions] = useState([])
    let [viewProduct, setViewProduct] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const { setSocketModal } = customCommonContext

    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    const formik = useFormik({
        initialValues: search.FormikSearch,
    })
    const onHandlePageAuction = (event, value) => {
        formik.setFieldValue(`page`, value)
    }

    // handler
    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('disconnect', (data) => {
            console.log('data Server', data)
            if (data !== 'io client disconnect') {
                setSocketModal()
            }
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])
    const handleChange = (e) => {
        const { name, value } = e.currentTarget
        console.log(name, value, 'sdfasdf')
        formik.values.filters[name] = value
    }

    const onChangePage = (event, value) => {
        formik.setFieldValue(`limit`, event.target.value)
        formik.setFieldValue(`page`, 1)
    }
    useEffect(() => {
        setIsLoading(true)
        randomVariable = Math.random()
        getAllAuctionProducts(formik.values, randomVariable)
    }, [formik.values])

    useEffect(() => {
        if (search_allauctions.from === randomVariable) {
            setAuctions(search_allauctions.records.length ? search_allauctions.records : [])
            setIsLoading(false)
        }
    }, [search_allauctions])

    return (
        <>
            <SEO title="Auctions" url={window.location.href} />
            <div className="auctionSearch searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-end">
                <div className="gridListToggle"></div>
                {props.searchbytitle && (
                    <CustomInput
                        label="Auction "
                        className="auctioninput"
                        placeholder="Search"
                        size="small"
                        onChange={(e) => {
                            formik.setFieldValue('filters.title.value', e.target.value)
                        }}
                    />
                )}
                {pageOptions ? (
                    <CustomSelect
                        label="Results"
                        name="resultsPerPage"
                        selectType="noBorder"
                        size="small"
                        value={formik.values.limit}
                        onChange={onChangePage}
                    >
                        {pageOptions.map((opt, optindex) => (
                            <option value={opt.value} key={optindex}>
                                {opt.show}
                            </option>
                        ))}
                    </CustomSelect>
                ) : null}
                {sortShow ? (
                    <CustomSelect
                        label="Sort by"
                        value={formik.values.orderby}
                        size="small"
                        selectType="noBorder"
                        name="orderby"
                        // shrink={search.orderby !== '' ? true : false}
                        onChange={(event, editor) => {
                            formik.setFieldValue(`orderby`, event.target.value)
                        }}
                    >
                        {sortShow.map((opt, optindex) => (
                            <option value={opt.value}>{opt.show}</option>
                        ))}
                    </CustomSelect>
                ) : null}
            </div>
            <div className="searchCnt d-flex justify-content-start align-items-start">
                <section className="homeRecentlyListed homeMarginBottom w-100">
                    {isLoading ? (
                        <div className="noGridLoader">
                            <Loaders name="product_list_view" isLoading={isLoading} loop={4} />
                        </div>
                    ) : auctions.length ? (
                        <div className="homeSpecialItems">
                            <div className="auctionsGridCnt">
                                {auctions.map((data, index) => (
                                    <>
                                        {data.auctiontype === 'sealed' ? (
                                            <OfferCard data={data} />
                                        ) : (
                                            <AuctionCard data={data} />
                                        )}
                                    </>
                                ))}
                            </div>

                            <div
                                id="auctionpage_pagination"
                                className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper"
                            >
                                <h6>
                                    Showing {search_allauctions.setDisp} of{' '}
                                    {search_allauctions.totalRecords}
                                </h6>
                                <Pagination
                                    count={Math.ceil(
                                        search_allauctions.totalRecords / formik.values.limit,
                                    )}
                                    page={formik.values.page}
                                    onChange={onHandlePageAuction}
                                    siblingCount={3}
                                    showFirstButton
                                    showLastButton
                                    boundaryCount={2}
                                />
                            </div>
                        </div>
                    ) : (
                        <NoRecordsFound />
                    )}
                </section>
            </div>
        </>
    )
}
export default AuctionSearch
