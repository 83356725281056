import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import PrimaryButton from '../../../../../product/components/atoms/PrimaryButton'
import '../../../molecules/ProductCard/ProductCard.css'
import SecondaryButton from '../../../../../product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '../../../../product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import BidContext from '../../../../../product/context/reverse/bid/bidContext'
import AuthContext from '../../../../../product/context/auth/authContext'

import CustomDialog from '../../../organisms/Dialog'
import { currencyFormat } from '../../../../../custom/common/components'
import { mapData, handleRedirectInternal } from '../../../../../product/common/components'

const Bidding = (props) => {
    const bidContext = useContext(BidContext)
    const authContext = useContext(AuthContext)

    const history = useHistory()

    let { bidConfirm } = bidContext
    const { user, isAuthenticated } = authContext
    const [product, setProduct] = React.useState()

    const [toggleDialog, setToggleDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const validationArray = Yup.object({
        wsprice: Yup.number()
            .min(1, `${'Min Bid'} ${currencyFormat(1)}`)
            .max(
                product ? product.sprice : 0,
                `${'Max Starting Bid'} ${
                    product ? currencyFormat(product.sprice) : currencyFormat(0)
                }`,
            )
            // .max(
            //     product ? product.next_bid : 0,
            //     `${'Max Bid'} ${product ? currencyFormat(product.next_bid) : currencyFormat(0)}`,
            // )
            .required('Enter Bid Amount'),
    })

    const formik = useFormik({
        initialValues: {
            wsprice: '',
            id: props.data.id,
            bidding_type: props.type,
        },
        validationSchema: validationArray,
        enableReinitialize: true,
        onSubmit: (values) => {
            if (toggleDialog) {
                bidConfirm(values)
                formik.values.wsprice = ''
                setToggleDialog(false)
            } else {
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        formik.values.bidding_type = props.type
    }, [props.type])

    const removeAlpha = (evt) => {
        const re = /^[+-]?\d*(?:[.,]\d*)?$/
        if (re.test(evt.target.value)) {
            formik.handleChange(evt)
        }
        // evt.target.value === 'e' && evt.preventDefault()
    }
    const bidAmount = [
        {
            helperText:
                props.helpText &&
                (product && product.bidhistory.length > 0
                    ? 'Enter ' + currencyFormat(product.next_bid) + ' or less'
                    : product && 'Maximum Bid' + ' ' + currencyFormat(product.next_bid)),
            label: 'Enter Bid Amt',
            name: 'wsprice',
            type: 'number',
            placeholder: `${'Max Bid'} ${
                product && product.next_bid ? currencyFormat(product.next_bid) : currencyFormat(0)
            }`,
            class: '',
            size: 'small',
            disabled: false,
            autoFocus: false,
            formik: formik,
            onChange: removeAlpha,
        },
    ]

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt d-flex justify-content-between align-items-start">
                                {Object.values(mapData(bidAmount))}

                                {isAuthenticated ? (
                                    product.bidtopstatus === 'winner' ? (
                                        <SecondaryButton
                                            label={
                                                formik.values.bidding_type === 'hard'
                                                    ? 'Place lower fixed bid'
                                                    : 'Lower Proxy Bid'
                                            }
                                            type="submit"
                                            btnSize="small"
                                            disabled={false}
                                        />
                                    ) : (
                                        <PrimaryButton
                                            label={
                                                formik.values.bidding_type === 'hard'
                                                    ? 'Place Fixed Single Bid'
                                                    : 'Place Proxy Bid'
                                            }
                                            type="submit"
                                            btnSize="small"
                                            disabled={false}
                                        />
                                    )
                                ) : (
                                    <PrimaryButton
                                        label={'Login to Bid'}
                                        btnSize="small"
                                        onClick={() => handleRedirectInternal(history, `login`)}
                                    />
                                )}
                            </div>
                        </form>
                    ) : null}

                    <CustomDialog
                        title={'Confirm Bid'}
                        className="customBidPopup"
                        open={toggleDialog}
                        function={changeDialogStatus}
                    >
                        {formik.values.wsprice < product.next_bid / 5 ? (
                            <>
                                <h5 className="lowBidWarning">
                                    <span className="material-icons">warning</span>
                                    Warning! You are about to place a bid on a much lower amount.
                                </h5>
                                <h5>{'Please confirm if you want to continue with this action'}</h5>
                            </>
                        ) : (
                            <>
                                <h5>{'You are about to place a bid'}!</h5>
                                <h5>{'Please confirm if you want to continue with this action'}</h5>
                            </>
                        )}

                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialog(false)}>{'Cancel'}</Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                type="button"
                                label={'Submit'}
                            />
                        </div>
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
