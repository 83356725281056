const tinyMCEImageUpload = (blobinfo, progress) =>
    new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.withCredentials = false
        xhr.open(
            'POST',
            `${global.site_url}/api/commonFunction/image_uploader/muliple_img_uploader`,
        )
        xhr.upload.onprogress = (e) => {
            progress((e.loaded / e.total) * 100)
        }

        xhr.onload = () => {
            const urls = {}
            if (xhr.status === 403) {
                reject({ message: 'HTTP error: ' + xhr.status, remove: true })
                return
            }

            if (xhr.status < 200 || xhr.status >= 300) {
                reject('HTTP error: ' + xhr.status)
                return
            }

            const json = JSON.parse(xhr.responseText)

            if (json.status === 'success') {
                let file = json.data.responseData.files[0].file_name
                urls.default = `${global.site_url}/uploads/templates/${file}`
                resolve(urls.default)
            } else {
                reject('Upload Failed!')
            }

            if (!json || typeof json.location != 'string') {
                reject('Invalid JSON: ' + xhr.responseText)
                return
            }
        }

        xhr.onerror = function () {
            reject('Image Upload Failed')
        }

        const formData = new FormData()
        formData.append('folder', 'templates')
        formData.append('file_upload', blobinfo.blob(), blobinfo.filename())

        xhr.send(formData)
    })

export default tinyMCEImageUpload
