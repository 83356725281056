const CryptoJS = require('crypto-js')

const ENCRYPTION_KEY = process.env.REACT_APP_CRYPTO_KEY // Must be 256 bits (32 characters)
const IV_LENGTH = 16 // For AES, this is always 16

function encrypt(text) {
    // Generate a random IV
    const iv = CryptoJS.lib.WordArray.random(IV_LENGTH) // 16 bytes for AES-256-CBC

    // Encrypt the text
    const encrypted = CryptoJS.AES.encrypt(text, CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    })

    // Combine IV and encrypted data into a single string
    const ivHex = iv.toString(CryptoJS.enc.Hex)
    const encryptedHex = encrypted.ciphertext.toString(CryptoJS.enc.Hex)
    return `${ivHex}:${encryptedHex}`
}

function decrypt(text) {
    // Split the input into IV and encrypted text parts
    const textParts = text.split(':')
    const ivHex = textParts.shift()
    const encryptedHex = textParts.join(':')

    // Convert IV and encrypted text from hex to WordArray
    const iv = CryptoJS.enc.Hex.parse(ivHex)
    const encrypted = CryptoJS.enc.Hex.parse(encryptedHex)

    // Create a decryption object
    const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: encrypted,
    })

    // Decrypt the text
    const decrypted = CryptoJS.AES.decrypt(cipherParams, CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    })

    return decrypted.toString(CryptoJS.enc.Utf8)
}

module.exports = { decrypt, encrypt }
