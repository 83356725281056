import React, { useReducer } from 'react'
import CartContext from './cartContext'
import CartReducer from './cartReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_SEARCH,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_SINGLE_CART,
    CLEAR_SINGLE_CART,
    GET_AVAILABLE_APPOINT,
} from './cartTypes'

const CartState = (props) => {
    const initialState = {
        search_allproducts: {
            cartrecords: [],
            noncartrecords: [],
            from: '',
        },
        search_singlecart: {
            cartrecords: [],
            cartvalues: {},
            cartLocation: {},
            from: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(CartReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getSingleCartProducts = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'checkout', formData, '', 'cart')])
            const from = fromVariable ? fromVariable : 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CART,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        cartLocation: res.data.data.responseData.cartLocation,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getSellerSingleCartProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkout', formData, '', 'seller/cart'),
            ])
            const from = 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CART,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        cartLocation: res.data.data.responseData.cartLocation,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getSingleCartProductsForSeller = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkout', formData, '', 'seller/cart'),
            ])
            const from = 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CART,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        cartLocation: res.data.data.responseData.cartLocation,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllCartProducts = async (formData, fromVariable) => {
        try {
            console.log(fromVariable, 'ghii')
            const [res] = await Promise.all([apiCall('post', 'search', formData, '', 'cart')])
            const from = fromVariable ? fromVariable : 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                        noncartrecords: res.data.data.responseData.nonCartItems.length
                            ? res.data.data.responseData.nonCartItems
                            : [],
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    // Register User
    const changeOnCart = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'changeStatus', formData, '', 'cart')])
            resp.commonResponse(res.data, 'cartchange')
        } catch (err) {
            resp.commonErrorResponse('cartchange')
        }
    }

    const cancelItem = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'cancelItem', formData, '', 'cart')])
            resp.commonResponse(res.data, 'cartchange')
        } catch (err) {
            resp.commonErrorResponse('cartchange')
        }
    }

    const saveAddress = async (formData) => {
        try {
            const [res] = await Promise.all([apiCall('post', 'saveAddress', formData, '', 'cart')])
            resp.commonResponse(res.data, 'cartaddress')
        } catch (err) {
            resp.commonErrorResponse('cartaddress')
        }
    }

    const updateCheckout = async (formData, noAlert, fromVariable) => {
        try {
            const from = fromVariable ? fromVariable : 'update_checkout'
            const [res] = await Promise.all([
                apiCall('post', 'updateCheckout', formData, '', 'cart'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const clearSingleCart = () =>
        dispatch({
            type: CLEAR_SINGLE_CART,
        })

    const payCartWallet = async (formData) => {
        const from = 'payCartWallet'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'seller/credit')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const payCartOffline = async (formData) => {
        const from = 'payCartOffline'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'seller/offline')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    return (
        <CartContext.Provider
            value={{
                search_allproducts: state.search_allproducts,
                search_singlecart: state.search_singlecart,
                responseStatus: state.responseStatus,
                getSingleCartProducts,
                updateCheckout,
                clearResponse,
                saveAddress,
                getAllCartProducts,
                changeOnCart,
                cancelItem,
                clearSingleCart,
                getSingleCartProductsForSeller,
                payCartWallet,
                payCartOffline,
                getSellerSingleCartProducts,
            }}
        >
            {props.children}
        </CartContext.Provider>
    )
}

export default CartState
