import { Button, Divider, Fade, Tooltip } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import CustomInput from '@/product/components/atoms/Inputs/CustomInput'
import './ProductCard.css'
import FavouriteCheckbox from '@/product/components/atoms/FavoriteCheckbox'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import Bidding from '@/custom/components/molecules/Bidding/BiddingItem'
import SealedBidding from '@/custom/components/molecules/Bidding/SealedItem'

import Buynow from '@/custom/components/molecules/Buynow/BuynowItem'
import { handleRedirectInternal, dateFormatFront, currencyFormat } from '@/custom/common/components'
import { checkProductOpen } from '@/product/common/components'
import CommonContext from '@/product/context/common/commonContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import AuthContext from '@/product/context/auth/authContext'
import Timer from '@/product/common/timer'
// import Timer from '@/custom/common/timer'
import { FALLBACK_IMAGE } from '@/utils'
import ReverseBidding from '@/custom/components/molecules/Reverse/Bidding/BiddingItem'

const Grid = (props) => {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { setBidHistoryValue } = commonContext
    const { user, isAuthenticated } = authContext
    const { configVariables } = customCommonContext

    const [product, setProduct] = useState()
    const [reload, setReload] = useState(true)
    const history = useHistory()
    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const dateAddedReached = (data) => {
        if (product.future_active) {
            if (data) {
                setProduct({ ...product, future_active: 0 })
            }
        }
    }

    const dateClosedReached = (data) => {
        if (product.past_inactive) {
            if (data) {
                setProduct({ ...product, past_inactive: 0 })
            }
        }
    }
    return (
        <Fade in={true} timeout={600}>
            <div className="featuredCard">
                {product ? (
                    <>
                        <div className="fcLt">
                            <img
                                className="cursorDecoy"
                                onClick={props.drawerHandler}
                                src={`${global.site_url}/uploads/product/compressed/${product.avatar}`}
                                onError={(e) => FALLBACK_IMAGE(e)}
                                alt={product.title}
                            />
                        </div>
                        <div className="fcRt">
                            <div className="fcTimer">
                                <Timer
                                    date_added={product.date_added}
                                    date_closed={product.date_closed}
                                    withText={1}
                                    date_added_check={product.future_active}
                                    date_added_reached={dateAddedReached}
                                    date_closed_check={product.past_inactive}
                                    date_closed_reached={dateClosedReached}
                                    endText=""
                                    startText=""
                                    from="productView"
                                    closedText={
                                        product.auctiontype === 'sealed'
                                            ? 'Offer Closed'
                                            : product.market_status === 'open'
                                            ? 'Auction Closing'
                                            : 'Auction Closed'
                                    }
                                ></Timer>
                            </div>
                            <h2 className="cursorDecoy" onClick={props.drawerHandler}>
                                {product.title}
                            </h2>
                            {isAuthenticated ? (
                                <>
                                    {product.auction ? (
                                        <>
                                            <div className="fcBid">
                                                <h4>
                                                    {product.auctiontype === 'sealed' ? (
                                                        <>
                                                            {product.current_bid ? (
                                                                <div className="activeBid">
                                                                    {product.bid_or_not
                                                                        ? 'My Current Offer'
                                                                        : 'Starting Price'}
                                                                    :
                                                                    <span>
                                                                        {currencyFormat(
                                                                            product.current_bid,
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {product.cbidtext ==
                                                            'Starting Price' ? (
                                                                <>
                                                                    {product.cbidtext}:
                                                                    {product?.hide_start_price ? (
                                                                        <span>Hidden</span>
                                                                    ) : (
                                                                        <span>
                                                                            {currencyFormat(
                                                                                product.wprice,
                                                                            )}
                                                                        </span>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {product.cbidtext}:
                                                                    <span>
                                                                        {currencyFormat(
                                                                            product.wprice,
                                                                        )}{' '}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </h4>
                                                <FavouriteCheckbox
                                                    watchlisted={product.watchlistid}
                                                    project_id={product.id}
                                                />
                                            </div>
                                        </>
                                    ) : null}
                                </>
                            ) : null}
                            {product.market_status === 'open' && !product.future_active ? (
                                <>
                                    {isAuthenticated ? (
                                        <>
                                            {product.auctiontype === 'sealed' ? (
                                                <SealedBidding data={product} type={'sealed'} />
                                            ) : (
                                                <Bidding
                                                    data={product}
                                                    type={
                                                        parseInt(
                                                            configVariables?.proxy_bidding_settings,
                                                        ) === 0
                                                            ? 'hard'
                                                            : 'proxy'
                                                    }
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <PrimaryButton
                                            label={'Login to bid'}
                                            btnSize="small"
                                            onClick={() => handleRedirectInternal(history, 'login')}
                                        />
                                    )}
                                </>
                            ) : null}
                        </div>
                    </>
                ) : null}
            </div>
        </Fade>
    )
}

export default Grid
