import { useContext, useEffect } from 'react'
import AlertContext from './../context/alert/alertContext'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
const Alerts = ({ vposition, hposition }) => {
    const alertContext = useContext(AlertContext)
    const { alerts, clearAlert } = alertContext
    const { enqueueSnackbar } = useSnackbar()
    const { t } = useTranslation()
    // const classes = useStyles();
    // warning error info success
    console.log(vposition, hposition)
    return (
        alerts.length > 0 &&
        alerts.map((alert) => {
            enqueueSnackbar(t(alert.msg), {
                variant: alert.type,
                anchorOrigin: {
                    vertical: vposition ? vposition : 'bottom',
                    horizontal: hposition ? hposition : 'right',
                },
                preventDuplicate: true,
            })
            clearAlert(alert.id)
        })
    )
}

export default Alerts
