import React from 'react'
import './NoRecordsFound.css'

function NoRecordsFound() {
    return (
        <h4 className="noRecordsFound">
            <span className="material-icons">find_in_page</span>
            {'No Records found'}
        </h4>
    )
}

export default NoRecordsFound
