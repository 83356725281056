import openSocket from 'socket.io-client'
import moment from 'moment'

let socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })

let serverTime = new Date()

socket.on('connect', function () {
    console.log('Socket Connected')
})

socket.on('servertime', (data) => {
    if (moment(data.dTime).isValid()) {
        serverTime = new Date(data.dTime)
    }
})

function bidAddtime(cb) {
    socket.on('bidAddtime', (data) => {
        cb(data)
    })
}

function cancelBidEmitted(cb) {
    socket.on('cancelBidEmitted', (data) => {
        cb(data)
    })
}

function servertime(cb) {
    socket.on('servertime', (data) => {
        cb(data)
    })
}

function realClosedUpdates(cb) {
    socket.on('realclosedupdates', (data) => {
        cb(data)
    })
}

function refreshbuyer(cb) {
    socket.on('refreshbuyer', (data) => {
        cb(data)
    })
}
function refreshbuyerOff() {
    socket.off('refreshbuyer')
}
function bid_extended(cb) {
    socket.on('bid_extended', (data) => {
        cb(data)
    })
}
function bid_extendedOff() {
    socket.off('bid_extended')
}

socket.on('disconnect', (reason) => {
    console.log('Socket Disconnected', reason)
})

function reInitializeSocket() {
    socket.close()
    socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
}

export {
    bidAddtime,
    realClosedUpdates,
    refreshbuyer,
    socket,
    reInitializeSocket,
    refreshbuyerOff,
    bid_extended,
    bid_extendedOff,
    servertime,
    cancelBidEmitted,
    serverTime,
}
