import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Button } from '@material-ui/core'

export default function FormDialog(props) {
    const open = props.open
    const openFunction = props.function
    const title = props.title
    const maxWidth = props.maxWidth
    const handleClose = props.handleClose
    return (
        <div>
            <div>
                <Dialog
                    className={`customDialog ${props.className}`}
                    open={open}
                    maxWidth={maxWidth}
                    fullScreen={props.fullScreen ? props.fullScreen : false}
                    onClose={openFunction}
                    aria-labelledby="form-dialog-title"
                    disableBackdropClick={props.disableBackdropClick ? true : false}
                    // TransitionComponent={Transition}
                >
                    <div className="cdTop">
                        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                        {handleClose ? (
                            <Button id="dialog_closeBtn" className="closeBtn" onClick={handleClose}>
                                <span className="material-icons">close</span>
                            </Button>
                        ) : null}
                    </div>
                    <DialogContent>{props.children}</DialogContent>
                </Dialog>
            </div>
        </div>
    )
}
