import {
    GET_SINGLE_PROPERTY,
    GET_ALL_DYNAMIC_ARRAY,
    GET_ALL_DYNAMIC_VALUES,
    GET_ALL_DYNAMIC_TABLES,
    GET_ALL_TABLE_VALUES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './dynamicTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_SINGLE_PROPERTY:
            return {
                ...state,
                single_property: action.payload,
            }
        case GET_ALL_DYNAMIC_ARRAY:
            return {
                ...state,
                dynamicValue: action.payload,
            }
        case GET_ALL_TABLE_VALUES:
            return {
                ...state,
                dynamicTableValue: action.payload,
            }
        case GET_ALL_DYNAMIC_TABLES:
            return {
                ...state,
                ...action.payload,
            }
        case GET_ALL_DYNAMIC_VALUES:
            return {
                ...state,
                ...action.payload,
            }
        default:
            return state
    }
}
