import { box, randomBytes } from 'tweetnacl'
import { decodeUTF8, encodeUTF8, encodeBase64, decodeBase64 } from 'tweetnacl-util'

const newNonce = () => randomBytes(box.nonceLength)

export const encryptData = (data) => {
    const pairBprivate = decodeBase64(decryptBase64Key(process.env.REACT_APP_PAIR_B_PR))
    const pairApublic = decodeBase64(decryptBase64Key(process.env.REACT_APP_PAIR_A_PB))

    // const pairBprivate = decodeBase64(process.env.REACT_APP_PAIR_B_PR)
    // const pairApublic = decodeBase64(process.env.REACT_APP_PAIR_A_PB)

    const secretOrSharedKey = box.before(pairApublic, pairBprivate)
    const key = null
    const nonce = newNonce()
    const messageUint8 = decodeUTF8(JSON.stringify(data))
    const encrypted = key
        ? box(messageUint8, nonce, key, secretOrSharedKey)
        : box.after(messageUint8, nonce, secretOrSharedKey)

    const fullMessage = new Uint8Array(nonce.length + encrypted.length)
    fullMessage.set(nonce)
    fullMessage.set(encrypted, nonce.length)

    const base64FullMessage = encodeBase64(fullMessage)
    return { data: base64FullMessage }
}

export const decryptData = (data) => {
    const pairBprivate = decodeBase64(decryptBase64Key(process.env.REACT_APP_PAIR_B_PR))
    const pairApublic = decodeBase64(decryptBase64Key(process.env.REACT_APP_PAIR_A_PB))

    // const pairBprivate = decodeBase64(process.env.REACT_APP_PAIR_B_PR)
    // const pairApublic = decodeBase64(process.env.REACT_APP_PAIR_A_PB)

    const secretOrSharedKey = box.before(pairApublic, pairBprivate)
    const key = null
    const messageWithNonce = data
    const messageWithNonceAsUint8Array = decodeBase64(messageWithNonce)
    const nonce = messageWithNonceAsUint8Array.slice(0, box.nonceLength)
    const message = messageWithNonceAsUint8Array.slice(box.nonceLength, messageWithNonce.length)

    const decrypted = key
        ? box.open(message, nonce, key, secretOrSharedKey)
        : box.open.after(message, nonce, secretOrSharedKey)

    if (!decrypted) {
        throw new Error('Could not decrypt message')
    }

    const base64DecryptedMessage = encodeUTF8(decrypted)
    return JSON.parse(base64DecryptedMessage)
}

export const encryptBase64Key = (originalEncodedString) => {
    var shift = 3
    var encryptedString = ''
    for (var i = 0; i < originalEncodedString.length; i++) {
        var charCode = originalEncodedString.charCodeAt(i)
        var shiftedCharCode = charCode + shift
        encryptedString += String.fromCharCode(shiftedCharCode)
    }
    return encryptedString
}

export const decryptBase64Key = (encryptedString) => {
    var shift = 3
    var decryptedString = ''
    for (var i = 0; i < encryptedString.length; i++) {
        var charCode = encryptedString.charCodeAt(i)
        var shiftedCharCode = charCode - shift
        decryptedString += String.fromCharCode(shiftedCharCode)
    }
    return decryptedString
}
