import React, { useState, useEffect, useContext, useRef } from 'react'
import Header from '@/custom/components/molecules/Header'
import './Home.css'
import AuctionSearch from '../Auction/AuctionSearch'
import SearchComp from '../Search/SearchComp'
import { useFormik } from 'formik'
import ListView from '@/custom/components/molecules/ProductCard/ListView'
import ProductContext from '@/product/context/product/productContext'
import AuthContext from '@/product/context/auth/authContext'
import { useHistory } from 'react-router-dom'
import Slider from 'react-slick'
import Timer from '@/product/common/timer'
import { FALLBACK_IMAGE } from '@/utils/index'
import FavouriteCheckbox from '@/product/components/atoms/FavoriteCheckbox'
import Bidding from '@/custom/components/molecules/Bidding/BiddingItem'
import { currencyFormat } from '@/custom/common/components'
import { checkProductOpen, handleRedirectInternal } from '@/product/common/components'
import { Drawer } from '@material-ui/core'
import CustomCommonContext from '@/custom/context/common/commonContext'
import ProductView from '@/custom/components/organisms/ProductView'
import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import HomePageCarousel from '../../custom/components/molecules/HomePageCarousel'
import { TurnedIn } from '@material-ui/icons'
import SEO from '@/utils/SEO'
import Loaders from '@/custom/components/molecules/Loaders'
import { useMediaQuery } from 'react-responsive'
import AlertContext from '@/product/context/alert/alertContext'
import openSocket from 'socket.io-client'
import { messageHandler } from '@/product/common/socketHandler'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import FeaturedView from '@/custom/components/molecules/ProductCard/FeaturedView'

const Home = () => {
    const history = useHistory()
    const { getAllSearchProducts, search_allproducts, responseStatus } = useContext(ProductContext)

    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext

    const { user, isAuthenticated } = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { setSocketModal, configVariables, getGlobalVariable, allConfigurationVariables } =
        customCommonContext
    const [position, setPosition] = useState(window.pageYOffset)
    const [visible, setVisible] = useState(true)
    const [auctionView, setAuctionView] = useState('List')
    const [featuredProducts, setFeaturedProducts] = useState([])
    const [bannerImg, setBannerImg] = useState([])
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })

    useEffect(() => {
        const bodyElements = document.getElementsByTagName('body')
        const bodyElement = bodyElements[0]

        if (state.right === true) {
            bodyElement.classList.add('noScroll')
        } else {
            bodyElement.classList.remove('noScroll')
        }
    }, [state.right])

    const [scrollHeight, setScrollHeight] = useState(600)

    const isTablet = useMediaQuery({
        query: '(max-width: 1024px)',
    })

    const isMobile = useMediaQuery({
        query: '(max-width: 600px)',
    })

    const settings = {
        dots: true,
        infinite: true,
        // autoplay: true,
        adaptiveHeight: true,
        // variableWidth: true,
        swipeToSlide: true,
        // autoplaySpeed: 2000,
        // speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 915,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    }
    useEffect(() => {
        getGlobalVariable()
    }, [])

    useEffect(() => {
        if (isMobile) {
            setScrollHeight(300)
        } else if (isTablet) {
            setScrollHeight(450)
        }
    }, [isMobile, isTablet])

    useEffect(() => {
        const handleScroll = () => {
            let moving = window.pageYOffset
            if (moving <= scrollHeight) {
                setVisible(position > moving)
                setPosition(moving)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })

    const search = {
        FormikSearch: {
            limit: 12,
            page: 1,
            orderby: 'ac.date_closed, asc',
            filters: {
                title: {
                    value: '',
                    type: 'like',
                    field: 'ac.title',
                },
            },
        },
    }

    useEffect(() => {
        getAllSearchProducts(
            {
                limit: 6,
                page: 1,
                orderby: 'p.title, asc',
                order: '',
                filters: {
                    featured: {
                        value: 1,
                        type: 'greaterequal',
                        field: 'it.featured',
                    },
                },
            },
            'featured',
        )
    }, [])

    useEffect(() => {
        if (search_allproducts.from === 'featured') {
            if (search_allproducts.records.length > 0) {
                let products = [...search_allproducts.records]
                setLoading(true)
                for (let product of products) {
                    product.images = product.allattachmentlist
                        .filter((attachment) => {
                            return attachment.type === 1
                        })
                        .map((attachment) => {
                            return {
                                original: `${global.images_compressed_url}${attachment.file_name}`,
                                thumbnail: `${global.images_compressed_url}${attachment.file_name}`,
                            }
                        })
                }
                setFeaturedProducts(products)
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            } else {
                setFeaturedProducts([])
                setTimeout(() => {
                    setLoading(false)
                }, 1000)
            }
        }
    }, [search_allproducts])

    useEffect(() => {
        if (state.right) {
            const index = featuredProducts.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: featuredProducts[index] })
            }
        }
    }, [featuredProducts])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    const viewProductRef = useRef(featuredProducts)
    const userRef = useRef(user)
    useEffect(() => {
        viewProductRef.current = featuredProducts
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setFeaturedProducts,
            type,
            null,
            { sealedSuccess: 'Offer Placed Successfully' },
        )
    }

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('disconnect', (data) => {
            console.log('data Server', data)
            if (data !== 'io client disconnect') {
                setSocketModal()
            }
        })

        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    const [reload, setReload] = useState(true)

    const dateReached = (data) => {
        if (product.future_active) {
            if (data) {
                setProduct({ ...product, future_active: 0 })
            }
        }
    }

    useEffect(() => {
        if (responseStatus) {
            if (
                responseStatus.from === 'addWatchlist' ||
                responseStatus.from === 'removeWatchlist'
            ) {
                handler(
                    {
                        id: responseStatus.data.project_id,
                        status: responseStatus.data.status,
                    },
                    'watchlistAdded',
                )
            }
        }
    }, [responseStatus])

    return (
        <>
            {loading ? (
                <Loaders name="home" isLoading={loading} />
            ) : (
                <>
                    <SEO title="Home" url={window.location.href} />

                    <div className={`headerSticky show`}>
                        <Header />
                    </div>
                    <div className="home">
                        <section className="heroBanner">
                            <HomePageCarousel />
                        </section>
                        {/* <img
                    src="/assets/images/homeBanner.webp"
                    onError={(e) => (e.target.src = '/assets/images/homeBanner.png')}
                    alt="Home banner"
                /> */}

                        {/* <div className="homeBannerCaption">
                    <h1>
                        De Beers Group <span>Auctions</span>
                    </h1>
                    <p>Small miracles of nature, infinite opportunities</p>
                </div> */}
                        {/* <ListView
                    data={data}
                    favId={`searchProd_${index}`}
                    drawerHandler={toggleDrawer('right', true, data)}
                /> */}
                        {/* <FeaturedCard product={featuredProducts} /> */}
                        <h3 className="featuredTitle">Featured Lots</h3>
                        <div className="container-xl featuredCnt">
                            {featuredProducts.length ? (
                                <Slider {...settings}>
                                    {featuredProducts.map((data, index) => (
                                        <>
                                            <FeaturedView
                                                data={data}
                                                favId={`searchProd_${index}`}
                                                drawerHandler={toggleDrawer('right', true, data)}
                                            />
                                        </>
                                    ))}
                                </Slider>
                            ) : (
                                <NoRecordsFound />
                            )}
                        </div>
                        <div className="homeAbout">
                            <div className="container-xl">
                                <img
                                    src={`${
                                        allConfigurationVariables.homepage_image
                                            ? `${global.site_url}/uploads/config/${allConfigurationVariables.homepage_image}`
                                            : null
                                    }`}
                                    alt="Homepage Image"
                                    className="aboutBanner"
                                />
                                <h3>
                                    {allConfigurationVariables.homepage_title
                                        ? `${allConfigurationVariables.homepage_title}`
                                        : null}
                                </h3>
                                <p>
                                    <div
                                        className="text"
                                        dangerouslySetInnerHTML={{
                                            __html: allConfigurationVariables.homepage_text
                                                ? allConfigurationVariables.homepage_text
                                                : null,
                                        }}
                                    ></div>
                                </p>
                            </div>
                        </div>
                        <div className="homeAuctions container-xl">
                            <h2 className="haTitle">Active & Upcoming Auctions</h2>
                            <AuctionSearch search={search} searchbytitle={false} />
                        </div>
                    </div>
                    <Drawer
                        className="rightDrawer"
                        anchor={'right'}
                        open={state['right']}
                        onClose={toggleDrawer('right', false)}
                        modalProps={{ disableBackdropClick: true }}
                    >
                        <ProductView
                            data={state.data}
                            drawerHandler={toggleDrawer('right', false)}
                        />
                    </Drawer>
                </>
            )}
        </>
    )
}

export default Home
