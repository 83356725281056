import React, { useState, useRef, useEffect, useContext } from 'react'
import { Button, Divider, Fade, Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import CustomInput from '@/product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import './ProductCard.css'
import FavouriteCheckbox from '@/product/components/atoms/FavoriteCheckbox'
// import Popup from '@/custom/organisms/Popup'
import Timer from '@/product/common/timer'
import Buynow from '../Buynow/BuynowItem'
import {
    handleRedirectInternal,
    currencyFormat,
    dateFormatFront,
    HtmlToText,
    floatFormat,
} from '@/custom/common/components'
import { checkProductOpen } from '@/product/common/components'
import CommonContext from '@/product/context/common/commonContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import AuthContext from '@/product/context/auth/authContext'
import ReverseBidding from '@/custom/components/molecules/Reverse/Bidding/BiddingItem'
import Bidding from '../Bidding/BiddingItem'
import SealedBidding from '../Bidding/SealedItem'
import { FALLBACK_IMAGE } from '@/utils'
const ListView = (props) => {
    const [addCard, setAddCard] = useState(false)
    const [product, setProduct] = useState()
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { user, isAuthenticated } = authContext

    const { setBidHistoryValue } = commonContext
    const { configVariables } = customCommonContext

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const history = useHistory()

    const toggleModal = (state, action) => {
        setAddCard(!addCard)
    }

    const [reload, setReload] = useState(true)

    const dateAddedReached = (data) => {
        if (product.future_active) {
            if (data) {
                setProduct({ ...product, future_active: 0 })
            }
        }
    }

    const dateClosedReached = (data) => {
        if (product.past_inactive) {
            if (data) {
                setProduct({ ...product, past_inactive: 0 })
            }
        }
    }

    return (
        <Fade in={true} timeout={600}>
            <div className="productCardList d-flex justify-content-between align-items-start">
                {product ? (
                    <>
                        <div className="psListLt d-flex">
                            <div className="pclImg" onClick={props.drawerHandler}>
                                <img
                                    className="cursorDecoy"
                                    src={`${global.site_url}/uploads/product/compressed/${product.avatar}`}
                                    onError={(e) => FALLBACK_IMAGE(e)}
                                    alt={product.title}
                                    id="list_productimage"
                                />
                            </div>

                            <div className="listContent">
                                <h2
                                    onClick={props.drawerHandler}
                                    className="listProdTitle"
                                    id="list_producttitle"
                                >
                                    {product.title}
                                </h2>
                                <div className="listLotDetails">
                                    {HtmlToText(product.desc_proc)}
                                </div>

                                {/* <div className="listBidInfo d-flex justify-content-start align-items-center">
                            <h6>{t('auction')} id:</h6>
                            <p className="d-flex justify-content-between align-items-center">
                                <span>{`#${product.auctionid}`}</span>
                            </p>
                        </div> */}

                                <div className="listMiscGrid">
                                    <div className="listBidInfo d-flex justify-content-start align-items-center">
                                        <h6>Lot number:</h6>
                                        <p>
                                            <span>{product?.auction_lot}</span>
                                        </p>
                                    </div>
                                    <div className="listBidInfo d-flex justify-content-start align-items-center">
                                        <h6>Total weight:</h6>
                                        <p>
                                            <span>
                                                {floatFormat(product?.weight)}&nbsp;
                                                {product?.weight > 1 ? 'cts' : 'ct'}
                                            </span>
                                        </p>
                                    </div>
                                    <div className="listBidInfo d-flex justify-content-start align-items-center">
                                        <h6>Stone count:</h6>
                                        {product?.total_stones !== 0 &&
                                        product?.total_stones !== null ? (
                                            <p>
                                                <span>{product?.total_stones}</span>
                                            </p>
                                        ) : (
                                            'N/A'
                                        )}
                                    </div>
                                    <div className="listBidInfo d-flex justify-content-start align-items-center">
                                        <h6>Avg size:</h6>
                                        {product?.average_size !== 0 &&
                                        product?.average_size !== null ? (
                                            <p>
                                                <span>
                                                    {floatFormat(product?.average_size)}&nbsp;
                                                    {product?.average_size > 1 ? 'cts' : 'ct'}
                                                </span>
                                            </p>
                                        ) : (
                                            'N/A'
                                        )}
                                    </div>

                                    {product?.custom_bid_data?.length ? (
                                        <div className="listBidInfo d-flex justify-content-start align-items-center">
                                            <h6>Your Initial Offer:</h6>

                                            <p>
                                                <span>
                                                    {currencyFormat(
                                                        product?.custom_bid_data[0]?.offer_bid,
                                                    )}{' '}
                                                    per carat{' '}
                                                </span>
                                            </p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>

                        <div className="listActBtn">
                            {product.market_status === 'open' ? (
                                <>
                                    <div className="d-flex justify-content-center align-items-center flex-wrap">
                                        <p className="d-flex align-items-center timerListView flex-wrap">
                                            <span className="material-symbols-outlined">timer</span>
                                            {product.date_closed ? (
                                                <span>
                                                    <Timer
                                                        date_added={product.date_added}
                                                        date_closed={product.date_closed}
                                                        withText={1}
                                                        date_added_check={product.future_active}
                                                        date_added_reached={dateAddedReached}
                                                        date_closed_check={product.past_inactive}
                                                        date_closed_reached={dateClosedReached}
                                                        endText={'Time left' + ':'}
                                                        startText={'Starts in' + ':'}
                                                        closedText={
                                                            product.auctiontype === 'sealed'
                                                                ? 'Offer Closed'
                                                                : product.market_status === 'open'
                                                                ? 'Auction Closing'
                                                                : 'Auction Closed'
                                                        }
                                                    ></Timer>
                                                </span>
                                            ) : null}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="listTimer d-flex justify-content-between align-items-center flex-wrap">
                                        <h6 className="w-100">Closed on:</h6>
                                        <p className="d-flex justify-content-center align-items-center w-100">
                                            {dateFormatFront(product.date_closed)}
                                        </p>
                                    </div>
                                </>
                            )}
                            {console.log(product, 'checkproduct')}
                            <div className="dividerCnt">
                                <div className="listBidStatus">
                                    {isAuthenticated && (
                                        <>
                                            {product.auctiontype !== 'sealed' ? (
                                                <>
                                                    {product.bidtopstatus === 'outbid' && (
                                                        <h4
                                                            className="productWinningStatus outbid"
                                                            id={
                                                                product.wprice < product.rprice
                                                                    ? 'list_outbid_reservenotmet'
                                                                    : 'list_outbid'
                                                            }
                                                        >
                                                            {product.wprice < product.rprice
                                                                ? 'Outbid, Reserve not met'
                                                                : 'Outbid'}
                                                        </h4>
                                                    )}
                                                    {console.log('after winning', product)}
                                                    {product.bidtopstatus === 'winner' && (
                                                        <h4
                                                            className="productWinningStatus winning"
                                                            id={
                                                                product.wprice < product.rprice
                                                                    ? 'list_winning_reservenotmet'
                                                                    : 'list_winning'
                                                            }
                                                        >
                                                            {product.wprice < product.rprice
                                                                ? 'Winning, Reserve not met'
                                                                : 'Winning'}
                                                        </h4>
                                                    )}
                                                    {product.bidtopstatus === 'won' && (
                                                        <h4
                                                            className="productWinningStatus won"
                                                            id="list_won"
                                                        >
                                                            {'Won'}
                                                        </h4>
                                                    )}
                                                    {product.bidtopstatus === 'lost' && (
                                                        <h4
                                                            className="productWinningStatus lost"
                                                            id="list_lost"
                                                        >
                                                            {'Lost'}
                                                        </h4>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {product.bid_or_not ? (
                                                        <>
                                                            {product.market_status ===
                                                            'relisted' ? (
                                                                <>
                                                                    <h4
                                                                        className="productWinningStatus outbid"
                                                                        id={
                                                                            'offer_moved_to_auction'
                                                                        }
                                                                    >
                                                                        {'Moved to Auction'}
                                                                    </h4>
                                                                </>
                                                            ) : product.market_status === 'open' ? (
                                                                <>
                                                                    {product.past_inactive ? (
                                                                        <>
                                                                            {/* <h4
                                                                        className="productWinningStatus winning"
                                                                        id={'offer_active'}
                                                                    >
                                                                        {'Active'}
                                                                    </h4>  */}
                                                                        </>
                                                                    ) : (
                                                                        <h4
                                                                            className="productWinningStatus outbid"
                                                                            id={'offer_pending'}
                                                                        >
                                                                            {'Pending'}
                                                                        </h4>
                                                                    )}
                                                                </>
                                                            ) : null}

                                                            {product.market_status === 'sold' ? (
                                                                <>
                                                                    {parseInt(
                                                                        product.buynowed,
                                                                        10,
                                                                    ) === parseInt(user.id, 10) ? (
                                                                        <h4
                                                                            className="productWinningStatus won"
                                                                            id="offer_won"
                                                                        >
                                                                            {'Won'}
                                                                        </h4>
                                                                    ) : null}
                                                                    {parseInt(
                                                                        product.buynowed,
                                                                        10,
                                                                    ) !== parseInt(user.id, 10) ? (
                                                                        <h4
                                                                            className="productWinningStatus lost"
                                                                            id="offer_lost"
                                                                        >
                                                                            {'Lost'}
                                                                        </h4>
                                                                    ) : null}
                                                                </>
                                                            ) : product.market_status ===
                                                              'unsold' ? (
                                                                <>
                                                                    <h4
                                                                        className="productWinningStatus lost"
                                                                        id="offer_lost"
                                                                    >
                                                                        {'Lost'}
                                                                    </h4>
                                                                </>
                                                            ) : null}
                                                        </>
                                                    ) : null}
                                                </>
                                            )}
                                            {/* {(product.bidtopstatus === 'outbid' ||
                                        product.bidtopstatus === 'winner') &&
                                        product.wprice <= product.rprice && (
                                            <>
                                                <Tooltip title="Reserve price not met">
                                                    <span className="material-icons reserveNotMet">
                                                        lock_open
                                                    </span>
                                                </Tooltip>
                                            </>
                                        )} */}
                                        </>
                                    )}
                                </div>
                                <Divider />
                            </div>

                            {isAuthenticated ? (
                                <div className="listBidCnt">
                                    {product.market_status === 'open' ? (
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="w-100">
                                                <>
                                                    {product.auction ? (
                                                        <>
                                                            {product.auctiontype === 'sealed' ? (
                                                                <>
                                                                    {product.current_bid ? (
                                                                        <div className="listBidInfo d-flex justify-content-center align-items-center w-100 activeBid">
                                                                            <h6>
                                                                                {product.bid_or_not
                                                                                    ? 'My Current Offer'
                                                                                    : 'Starting Price'}
                                                                                :
                                                                            </h6>
                                                                            <p className="d-flex justify-content-between align-items-center">
                                                                                <span>
                                                                                    {currencyFormat(
                                                                                        product.current_bid,
                                                                                    )}{' '}
                                                                                    per carat{' '}
                                                                                </span>
                                                                            </p>
                                                                        </div>
                                                                    ) : null}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {product.cbidtext ==
                                                                    'Starting Price' ? (
                                                                        <div className="listBidInfo d-flex justify-content-center align-items-center w-100">
                                                                            <h6>
                                                                                {product.cbidtext}:
                                                                            </h6>
                                                                            <p className="d-flex justify-content-between align-items-center">
                                                                                {product?.hide_start_price ? (
                                                                                    <span>
                                                                                        Hidden
                                                                                    </span>
                                                                                ) : (
                                                                                    <span>
                                                                                        {currencyFormat(
                                                                                            product.wprice,
                                                                                        )}{' '}
                                                                                        per carat{' '}
                                                                                    </span>
                                                                                )}
                                                                            </p>
                                                                        </div>
                                                                    ) : (
                                                                        <>
                                                                            <div className="listBidInfo d-flex justify-content-center align-items-center w-100">
                                                                                <h6>
                                                                                    {
                                                                                        product.cbidtext
                                                                                    }
                                                                                    :
                                                                                </h6>
                                                                                <p className="d-flex justify-content-between align-items-center">
                                                                                    <span>
                                                                                        {currencyFormat(
                                                                                            product.wprice,
                                                                                        )}{' '}
                                                                                        per carat{' '}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    ) : (
                                                        ''
                                                    )}
                                                </>

                                                {product.buynow &&
                                                product.wprice < product.bprice ? (
                                                    <div className="listBidInfo d-flex justify-content-start align-items-center">
                                                        <h6>Buy Now Price:</h6>
                                                        <p className="d-flex justify-content-between align-items-center">
                                                            <span>
                                                                {currencyFormat(
                                                                    product.bprice * product.qty,
                                                                )}
                                                            </span>
                                                        </p>
                                                    </div>
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                            {product.market_status === 'open' &&
                                            product.auctiontype !== 'sealed' ? (
                                                <FavouriteCheckbox
                                                    watchlisted={product.watchlistid}
                                                    project_id={product.id}
                                                />
                                            ) : null}
                                        </div>
                                    ) : product.bid_or_not && product.buynowamount ? (
                                        <>
                                            {product.auctiontype === 'sealed' ? (
                                                <>
                                                    {product.market_status === 'sold' ? (
                                                        <>
                                                            {parseInt(product.buynowed, 10) ===
                                                            parseInt(user.id, 10) ? (
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <div className="listBidInfo d-flex justify-content-center align-items-center w-100">
                                                                        <h6>{product.cbidtext}:</h6>
                                                                        <p className="d-flex justify-content-between align-items-center">
                                                                            <span>
                                                                                {currencyFormat(
                                                                                    product.buynowamount,
                                                                                )}{' '}
                                                                                per carat
                                                                            </span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            ) : null}{' '}
                                                        </>
                                                    ) : null}
                                                </>
                                            ) : (
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="listBidInfo d-flex justify-content-center align-items-center w-100">
                                                        <h6>{product.cbidtext}:</h6>
                                                        <p className="d-flex justify-content-between align-items-center">
                                                            <span>
                                                                {currencyFormat(
                                                                    product.buynowamount,
                                                                )}{' '}
                                                                per carat
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : null}
                                </div>
                            ) : (
                                ''
                            )}

                            {props.from === 'dashboard' &&
                            (props.action === 'won' || props.action === 'buynow') &&
                            product.buynowed &&
                            product.buynowed === user.id ? (
                                <div className="actionAfterWin">
                                    {parseInt(product.buynowpaid, 10) === 1 ? (
                                        <>
                                            <SecondaryButton
                                                label="View invoice"
                                                btnSize="small"
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `invoice/${product.common_invoice}`,
                                                    )
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {/* <PrimaryButton
                                        label="Make payment"
                                        btnSize="small"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `checkout/invoice/${product.common_invoice}`,
                                            )
                                        }
                                    /> */}
                                        </>
                                    )}
                                </div>
                            ) : product.market_status === 'open' && !product.future_active ? (
                                <>
                                    <div className="listBidBox">
                                        {product.auction ? (
                                            <>
                                                {product.auctiontype == 'reverse' ? (
                                                    <ReverseBidding
                                                        data={product}
                                                        type={
                                                            parseInt(
                                                                configVariables?.proxy_bidding_settings,
                                                            ) === 0
                                                                ? 'hard'
                                                                : 'proxy'
                                                        }
                                                    />
                                                ) : (
                                                    <>
                                                        {isAuthenticated ? (
                                                            <>
                                                                {product.auctiontype ===
                                                                'sealed' ? (
                                                                    <SealedBidding
                                                                        data={product}
                                                                        type={'sealed'}
                                                                    />
                                                                ) : (
                                                                    <Bidding
                                                                        data={product}
                                                                        type={
                                                                            parseInt(
                                                                                configVariables?.proxy_bidding_settings,
                                                                            ) === 0
                                                                                ? 'hard'
                                                                                : 'proxy'
                                                                        }
                                                                    />
                                                                )}
                                                            </>
                                                        ) : (
                                                            <PrimaryButton
                                                                label={'Login to bid'}
                                                                btnSize="small"
                                                                onClick={() =>
                                                                    handleRedirectInternal(
                                                                        history,
                                                                        'login',
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <Buynow
                                                data={product}
                                                editQuantity={false}
                                                isOnlyBuynow={product.auction === 0 ? true : false}
                                            />
                                        )}
                                    </div>
                                </>
                            ) : product.buynowed && product.buynowed === user.id ? (
                                <div className="actionAfterWin">
                                    {parseInt(product.buynowpaid, 10) === 1 ? (
                                        <>
                                            <SecondaryButton
                                                label="View invoice"
                                                btnSize="small"
                                                onClick={() =>
                                                    handleRedirectInternal(
                                                        history,
                                                        `invoice/${product.common_invoice}`,
                                                    )
                                                }
                                            />
                                        </>
                                    ) : (
                                        <>
                                            {/* <PrimaryButton
                                        label="Make payment"
                                        btnSize="small"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `checkout/invoice/${product.common_invoice}`,
                                            )
                                        }
                                    /> */}
                                        </>
                                    )}
                                </div>
                            ) : null}

                            {product.market_status === 'open' &&
                            product.auctiontype === 'sealed' ? (
                                <div className="offerFavBox">
                                    <FavouriteCheckbox
                                        watchlisted={product.watchlistid}
                                        project_id={product.id}
                                        addLabel="Add to watchlist"
                                        removeLabel="Remove from watchlist"
                                    />
                                </div>
                            ) : null}
                        </div>
                    </>
                ) : null}
            </div>
        </Fade>
    )
}
export default ListView
