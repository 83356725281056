import React, { useContext, useEffect, useState } from 'react'
import './FilterPanel.css'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RadioBox, { GreenRadio } from '@/product/components/atoms/RadioBox'
import ProductContext from '@/product/context/product/productContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import ProductCommonContext from '@/product/context/common/commonContext'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import CheckBox from '@/product/components/atoms/CheckBox'
import { Slider } from '@material-ui/core'
import { mapData } from '@/product/common/components'
import { capitalize } from '@/custom/common/components'
import { SettingsPhoneTwoTone } from '@material-ui/icons'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
    root: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 50,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const FilterPanel = (props) => {
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const productContext = useContext(ProductContext)
    const { active_sorts, getAllActiveSorts } = productContext
    const { allCategory, allCondition, allLocations } = customCommonContext
    const { setSearchValue } = productCommonContext

    const [expanded, setExpanded] = React.useState('panel0')
    const [reload, setReload] = React.useState(false)
    const [show, setShow] = React.useState(false)
    const sortValues = props.sorts
    let formik = props.formik
    console.log(formik.values.filters, 'fffff')

    const [priceRange, setPriceRange] = useState(
        // formik.values.filters.price.value !== '' ? formik.values.filters.price.value : [],
        [0, 10000],
    )
    useEffect(() => {
        getAllActiveSorts({})
    }, [])

    useEffect(() => {
        console.log('filter reload', reload)
        if (props.show) {
            formik = props.update
            setReload(true)
            setShow(true)
        }
    }, [props.show])

    useEffect(() => {
        if (show) {
            let searchValue = formik.values.filters
            console.log(formik.values, 'gggggg')
            if (searchValue?.category?.value.length > 0) {
                formik.values.filters.category.value = searchValue.category.value.map((cat) => {
                    return cat.toString()
                })
            } else {
                formik.values.filters.category.value = []
            }
            if (searchValue?.condition?.value.length > 0) {
                formik.values.filters.condition.value = searchValue.condition.value.map((cat) => {
                    return cat.toString()
                })
            } else {
                formik.values.filters.condition.value = []
            }
            if (searchValue?.location?.value.length > 0) {
                formik.values.filters.location.value = searchValue.location.value.map((cat) => {
                    return cat.toString()
                })
            } else {
                formik.values.filters.location.value = []
            }
            if (searchValue?.min_price?.value > 0) {
                formik.values.filters.min_price.value = Number(searchValue.min_price.value)
            } else {
                formik.values.filters.min_price.value = ''
            }
            if (searchValue?.max_price?.value > 0) {
                formik.values.filters.max_price.value = Number(searchValue.max_price.value)
            } else {
                formik.values.filters.max_price.value = ''
            }
        }
    }, [show])

    let sortedCategories = []
    let sortedConditions = []
    let sortedCountries = []
    if (sortValues) {
        if (sortValues.categorysort) {
            sortedCategories = allCategory.filter((inner) =>
                sortValues.categorysort.includes(inner.id.toString()),
            )
        } else {
            sortedCategories = allCategory
        }
        if (sortValues.conditionsort) {
            sortedConditions = allCondition.filter((inner) =>
                sortValues.conditionsort.includes(inner.id.toString()),
            )
        } else {
            sortedConditions = allCondition
        }

        if (sortValues.locationsort) {
            sortedCountries = allLocations.filter((inner) =>
                sortValues.locationsort.includes(inner.id.toString()),
            )
        } else {
            sortedCountries = allLocations
        }
    } else {
        sortedCategories = allCategory
        sortedConditions = allCondition
        sortedCountries = allLocations
    }

    const priceRangeHandler = () => {
        formik.setFieldValue('filters.price', {
            value: priceRange,
            type: 'range',
            field: 'p.wprice',
        })
    }

    const filterValues = [
        {
            title: 'Location',
            type: 'check',
            name: 'location',
            item: allLocations.filter((main) => {
                if (
                    active_sorts && active_sorts.sorts && active_sorts.sorts.sortlocations
                        ? active_sorts.sorts.sortlocations.includes(main.id.toString())
                        : true
                ) {
                    return true
                }
            }),
        },
        // {
        //     title: 'Price',
        //     type: 'slider',
        //     name: 'price',
        //     item: priceRange,
        // },
        {
            title: 'Conditions',
            type: 'check',
            name: 'condition',
            item: allCondition,
        },
        {
            title: 'Auction',
            type: 'check',
            name: 'auctionid',
            item: [{ id: 1, description: 'Auction' }],
        },
        {
            title: 'Buynow',
            type: 'check',
            name: 'buynowid',
            item: [{ id: 1, description: 'Buynow' }],
        },
    ]
    function sliderText(value) {
        return `$ ${value}`
    }

    const handleChange = (panel) => (event, newExpanded) => {
        if (panel === expanded) {
            setExpanded(false)
        } else setExpanded(panel)
    }

    const priceFilter = [
        {
            label: 'From',
            type: 'number',
            class: 'col-sm-6 col-12 pl-0',
            name: 'min_price',
            placeholder: 'From Price',
            filter: true,
            formik: formik,
            size: 'small',
        },
        {
            label: 'To',
            type: 'number',
            class: 'col-sm-6 col-12 pl-0 pr-0',
            name: 'max_price',
            placeholder: 'To Price',
            filter: true,
            formik: formik,
            size: 'small',
        },
    ]
    const [isValue, setValue] = useState([])
    useEffect(() => {
        setValue(filterValues)
        setReload(!reload)
    }, [props.formik])
    return (
        <div className="filterPanel">
            <div className="d-flex justify-content-between align-items-center filterTitleCnt">
                <h4 className="fpTitle">{'Filters'}</h4>
                {props.clearSearch && (
                    <Button onClick={() => props.clearSearch()} className="filterClear">
                        <span className="material-icons">cancel</span>
                        {'Clear all'}
                    </Button>
                )}
            </div>
            <div className="filterAcc">
                {console.log(filterValues, 'filter')}
                {filterValues.map((data, index) => (
                    <Accordion
                        square
                        key={index}
                        expanded={
                            expanded === `panel${index}`
                            // || formik.values[data.name].length > 0
                        }
                        onChange={handleChange(`panel${index}`)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1d-content"
                            id={`panel${index}d-header`}
                        >
                            <h6 className="accTitle">{data.title}</h6>
                        </AccordionSummary>
                        <AccordionDetails>
                            {console.log(formik.values.filters[data.name], 'hhhhh')}
                            {data.item && data.item.length > 0 ? (
                                <div className="filterCheck d-flex align-items-center flex-wrap">
                                    {data.type === 'check' ? (
                                        data.item.map((d, i) => (
                                            <>
                                                <CheckBox
                                                    name={`filters.${data.name}.value`}
                                                    label={
                                                        data.name === 'location'
                                                            ? `${d.name}`
                                                            : capitalize(d.description)
                                                    }
                                                    checked={
                                                        formik.values.filters[
                                                            data.name
                                                        ].value.indexOf(d.id.toString()) !== -1
                                                            ? true
                                                            : false
                                                    }
                                                    value={d.id.toString()}
                                                    onChange={formik.handleChange}
                                                    // name={`filters.${data.name}.value`}
                                                    // label={
                                                    //     data.name === 'location'
                                                    //         ? `${d.name}`
                                                    //         : capitalize(d.description)
                                                    // }
                                                    // value={d.id.toString()}
                                                    // onChange={formik.handleChange}
                                                    // checked={
                                                    //     formik.values.filters[
                                                    //         data.name
                                                    //     ].value.indexOf(d.id.toString()) !== -1
                                                    //         ? true
                                                    //         : false
                                                    // }
                                                />
                                            </>
                                        ))
                                    ) : data.type === 'radio' ? (
                                        <>
                                            <RadioBox
                                                items={data.item}
                                                value={formik.values.filters[data.name].value}
                                                onChange={formik.handleChange}
                                                name={`filters.${data.name}.value`}
                                                int={1}
                                            />
                                        </>
                                    ) : (
                                        <div className="priceInput">
                                            {/* <h5>
                                                {sliderText(
                                                    formik.values.filters.min_price.value
                                                        ? formik.values.filters.min_price.value
                                                        : '0',
                                                )}
                                                <span> to </span>
                                                {sliderText(
                                                    formik.values.filters.max_price.value
                                                        ? formik.values.filters.max_price.value
                                                        : '0',
                                                )}
                                            </h5> */}

                                            <div className="row">{mapData(priceFilter)}</div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <small>{`${'No'} ${data.title} ${'Found'}`}</small>
                            )}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        </div>
    )
}
export default FilterPanel
