import { apiCallSeller } from './api'

class MyUploadAdapter {
    constructor(loader) {
        // The file loader instance to use during the upload.
        this.loader = loader
    }

    // Starts the upload process.
    upload() {
        // Return a promise that will be resolved when the file is uploaded.
        return this.loader.file.then(async (file) => {
            const urls = {}
            const formData = new FormData()
            formData.append('folder', 'templates')
            formData.append('file_upload', file)
            const [res] = await Promise.all([
                apiCallSeller('post', 'muliple_img_uploader', formData, '', 'image_uploader', true),
            ])
            if (res.data.status === 'success') {
                let file = res.data.data.responseData.files[0].file_name
                urls.default = `${global.site_url}/uploads/templates/${file}`
                return Promise.resolve({ urls })
            } else {
                return Promise.reject('Upload Failed!')
            }
        })
    }
}

export default MyUploadAdapter
