import {
    SHIPPING_QUOTE,
    SHIPPING_SAMPLE_QUOTE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './shipstationTypes'
export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case SHIPPING_QUOTE:
            return {
                ...state,
                shipping_quotes: action.payload,
            }
        case SHIPPING_SAMPLE_QUOTE:
            return {
                ...state,
                shipping_sample_quotes: action.payload,
            }

        default:
            return state
    }
}
