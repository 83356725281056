import React, { useState, useContext, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'

import { socket } from '@/product/common/socket'

// import { CKEditor } from '@ckeditor/ckeditor5-react'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

let serverTime = new Date()
const dateFormat = 'MM-DD-YYYY'
const dateTimeFormat = 'MM-DD-YYYY h:mm a'

if (socket) {
    socket.on('servertime', (data) => {
        if (moment(data.dTime).isValid()) {
            serverTime = new Date(data.dTime)
        }
    })
}

export const handleRedirectInternal = (history, path) => {
    history.push(`/${path}`)
    window.scrollTo(0, 0)
}
export const dateFormatFront = (data) => {
    return moment(data).isValid()
        ? `${moment(data).format('MMM Do')}, ${moment(data).format('h:mm a')}`
        : '-'
}

export const capitalize = (data) => {
    let dataReturn = '-'
    if (data) {
        dataReturn = data.charAt(0).toUpperCase() + data.slice(1).toLowerCase()
    }
    return dataReturn
}

export const paymentTypeRosoom = (data, t) => {
    let paymentType
    switch (data) {
        case 'CBDD':
            paymentType = 'cbd_direct_debit'
            break
        case 'CCGV':
            paymentType = 'visa_card'
            break
        case 'CCGM':
            paymentType = 'master_card'
            break
        case 'CBDC–CBD':
            paymentType = 'cbd_corporate_direct_debit'
            break
        case 'HSBC–HCBC':
            paymentType = 'hcbc_corporate_direct_debit'
            break
        case 'EBDD':
            paymentType = 'emirates_nbd_bank_direct_debit'
            break
        case 'SCBD':
            paymentType = 'standard_chartered_direct_debit'
            break
        case 'HBZD':
            paymentType = 'habib_bank_direct_debit'
            break
        case 'MBLC':
            paymentType = 'mashreq_bank_corporate_direct_debit'
            break
        case 'DIBD':
            paymentType = 'dubai_islamic_bank_direct_debit'
            break
        case 'SCBC':
            paymentType = 'standard_chartered_corporate_direct_debit'
            break
        case 'MOBE':
            paymentType = 'ministry_of_finance_industry_blue'
            break

        case 'MOGE':
            paymentType = 'ministry_of_finance_industry_gold'
            break
        case 'MORE':
            paymentType = 'ministry_of_finance_industry'
            break
        default:
            paymentType = data
    }
    return paymentType
}

export const HtmlToText = (htmlString) => {
    var stringContainer = document.createElement('span')
    stringContainer.innerHTML = htmlString
    return stringContainer.textContent || stringContainer.innerText || ''
}

export const dateFormatFrontDay = (data) => {
    return `${moment(data).format('MMM Do YYYY')}`
}

export const floatFormat = (data) => {
    return `${parseFloat(data).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`
}

export const currencyFormat = (data) => {
    return `$${parseFloat(data).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })}`
}

export const dateFormatFunction = (data) => {
    let dataReturn = '-'
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(dateFormat)}`
        }
    }
    return dataReturn
}

export const checkProductOpen = (date_added, user_role) => {
    let startDate = new Date(serverTime)
    let addDate = new Date(date_added)
    let milliSeconds = 0
    // if (parseInt(user_role) !== 3) {
    //   milliSeconds = 1 * 3600000;
    // } else {
    // }
    let incrementedTime = addDate.getTime() + parseInt(milliSeconds)
    let newaddDate = new Date(incrementedTime)
    if (newaddDate > startDate) {
        return false
        // let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
        // let timerTime = incrementedTime - startDate.getTime();
        // if (timerTime <= 0) {
        //   return true;
        // } else {
        //   return false;
        // }
    } else {
        return true
    }
}

export const maskEmailFront = (data) => {
    data = data.toString()
    var first4 = data.substring(0, 2)
    var last5 = data.substring(data.length - 2)
    var mask = data.substring(2, data.length - 2).replace(/[^]/g, '*')
    return first4 + mask + last5
}

export const dateFormatFrontOnlyDate = (data) => {
    return `${moment(data).format(dateFormat)}`
}

export const dateTimeFormatFunction = (data) => {
    let dataReturn = '-'
    if (data) {
        if (moment(data).isValid()) {
            // dataReturn = `${moment(data).format(dateTimeFormat)}`
            dataReturn = `${moment(data).format('LLLL')}`
        }
    }
    return dataReturn
}

export const DirectAPICAll = (method, url) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (method === 'get') {
                let res = await axios.get(`${url}`)
                console.log('responsode from api', res)
                resolve(res)
            } else if (method === 'post') {
                let res = await axios.post(`${url}`)
                resolve(res)
            }
        } catch (err) {
            console.log('responsode error from api', err)
            resolve(err)
        }
    })
}

export const useCustomMediaQuery = (queryString) => {
    const [isMatch, setIsMatch] = useState(false)
    const mqChange = (mq) => {
        setIsMatch(mq.matches)
    }

    useEffect(() => {
        const mq = window.matchMedia(queryString)
        mq.addListener(mqChange)
        mqChange(mq)

        return () => {
            mq.removeListener(mqChange)
        }
    }, [queryString])

    return isMatch
}
