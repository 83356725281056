import React, { useState, useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Drawer, ListItem } from '@material-ui/core'

import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import './ProductCard.css'
import CommonContext from '@/product/context/common/commonContext'
import { useFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { dateTimeFormatFunction } from '@/custom/common/components'
import { FALLBACK_IMAGE } from '@/utils'
import Timer from '@/product/common/timer'

const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

function AuctionCard(props) {
    const commonContext = useContext(CommonContext)
    const { setSearchValue, allLocations } = commonContext
    const [auction, setAuction] = useState({})

    const history = useHistory()
    const classes = useStyles()

    const [state, setState] = useState({
        bottom: false,
    })

    useEffect(() => {
        setAuction(props.data)
    }, [props.data])

    const formik = useFormik({
        initialValues: {
            auctionid: '',
        },
    })

    const auctionRedirect = (id) => {
        history.push({
            pathname: '/liveAuction/' + id,
            state: auction,
        })
        window.scrollTo(0, 0)
    }

    const handleRedirectInternal = (path) => {
        history.push(`/${path}`)
        window.scrollTo(0, 0)
    }

    const handleClose = (state) => {
        state(false)
    }

    const handleOpen = (state) => {
        state(true)
    }

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    return (
        <div className="auctionCard d-flex justify-content-between align-items-stretch">
            <div className="auctionCardLt">
                <div className="acImg">
                    <img
                        src={`${global.site_url}/uploads/auction/${auction.avatar}`}
                        onError={(e) => FALLBACK_IMAGE(e)}
                        className="cursorDecoy"
                        onClick={() => auctionRedirect(auction.id)}
                        alt="Live Auction"
                        id="auctionpage_image"
                    />
                </div>
                <div className="acContent">
                    <h2
                        onClick={() => auctionRedirect(auction.id)}
                        className="cursorDecoy"
                        id="auctionpage_title"
                    >
                        {auction.title}
                    </h2>
                    <p className="acAddress">
                        <p
                            dangerouslySetInnerHTML={{
                                __html: auction.description,
                            }}
                        ></p>
                    </p>
                    {/* <p className="acStartDate">
                        <span>{'Start date'}:</span>
                        {dateTimeFormatFunction(auction.date_added)}
                    </p> */}
                    <p className="acStartDate">
                        <span className="material-icons">event</span>
                        <span className="acText">Bidding Ends:</span>
                        {dateTimeFormatFunction(auction.date_closed)}
                    </p>
                </div>
            </div>
            <div className="acActBtn">
                <div className="acTimer d-flex justify-content-center flex-wrap">
                    <div>
                        <span className="material-symbols-outlined mr-2">timer</span>
                        {auction.date_closed ? (
                            <div className="timerText">
                                <Timer
                                    date_added={auction.date_added}
                                    date_closed={auction.date_closed}
                                    withText={1}
                                    endText={'Time left' + ':'}
                                    startText={'Starts in' + ':'}
                                    closedText={
                                        auction.market_status === 'open'
                                            ? 'Auction Closing'
                                            : 'Auction Closed'
                                    }
                                ></Timer>
                            </div>
                        ) : null}
                    </div>
                    <PrimaryButton
                        onClick={() => auctionRedirect(auction.id)}
                        id="enter_auction_button"
                        label="Enter Auction"
                        btnSize="small"
                    />
                </div>
            </div>
        </div>
    )
}

export default AuctionCard
