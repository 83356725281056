import React, { useReducer } from 'react'
import ArtaContext from './artaContext'
import ArtaReducer from './artaReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'

import { SHIPPING_QUOTE, CLEAR_RESPONSE, RESPONSE_STATUS } from './artaTypes'

const ArtaState = (props) => {
    const initialState = {
        responseStatus: null,
        shipping_quotes: [],
    }

    const [state, dispatch] = useReducer(ArtaReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const shippingInitiate = async (formData) => {
        const from = 'getQuoteRequest'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'shipping/arta')])
            if (res.data.status === 'success') {
                dispatch({
                    type: SHIPPING_QUOTE,
                    payload: {
                        shipping_quotes: res.data.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const confirmShippingQuote = async (formData) => {
        const from = 'confirmShippingQuote'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'shipping/arta')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <ArtaContext.Provider
            value={{
                responseStatus: state.responseStatus,
                shipping_quotes: state.shipping_quotes,
                confirmShippingQuote,
                shippingInitiate,
                clearResponse,
            }}
        >
            {props.children}
        </ArtaContext.Provider>
    )
}

export default ArtaState
