import React, { useReducer } from 'react'
import AppointmentContext from './appointmentContext'
import AppointmentReducer from './appointmentReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_AVAILABLE_APPOINTMENT,
    GET_ALL_APPOINTMENTS_AUCTION,
    GET_ALL_APPOINTMENTS_USER,
    GET_APPOINTMENT_DETAIL_USER,
    GET_INVOICE_APPOINTMENT,
} from './appointmentTypes'

const AppointmentState = (props) => {
    const initialState = {
        search_allproducts: {
            cartrecords: [],
            noncartrecords: [],
        },
        available_appointments: {
            records: [],
        },
        all_appointments_auction: {
            records: [],
        },
        all_appointments_user: {
            records: [],
        },
        appointment_detail_user: {
            records: [],
        },
        search_singlecart: {
            cartrecords: [],
            cartvalues: {},
            cartLocation: {},
        },
        invoice_appointment: {
            records: [],
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AppointmentReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAvailbleAppointments = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkAppointments', formData, '', 'appointment'),
            ])
            const from = 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_AVAILABLE_APPOINTMENT,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllAppointmentsForAuction = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkAppointmentsForAuction', formData, '', 'appointment'),
            ])
            const from = 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_APPOINTMENTS_AUCTION,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const getAllAppointmentsForUser = async (formData, fromVariable) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getAppointments', formData, '', 'appointment'),
            ])
            const from = fromVariable || 'dashboard'
            if (res.data.status === 'success') {
                if (from === 'appointment_detail') {
                    dispatch({
                        type: GET_APPOINTMENT_DETAIL_USER,
                        payload: {
                            records: res.data.data.responseData.records.length
                                ? res.data.data.responseData.records
                                : [],
                            totalRecords: res.data.data.responseData.totalRecords.length
                                ? res.data.data.responseData.totalRecords.length
                                : 0,
                        },
                    })
                } else {
                    dispatch({
                        type: GET_ALL_APPOINTMENTS_USER,
                        payload: {
                            records: res.data.data.responseData.records.length
                                ? res.data.data.responseData.records
                                : [],
                            totalRecords: res.data.data.responseData.totalRecords.length
                                ? res.data.data.responseData.totalRecords.length
                                : 0,
                        },
                    })
                }
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('dashboard')
        }
    }

    const getAppointmentForInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getAppointmentForInvoice', formData, '', 'appointment'),
            ])
            const from = 'dashboard'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_INVOICE_APPOINTMENT,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        calendars: res.data.data.responseData.calendars.length
                            ? res.data.data.responseData.calendars
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('dashboard')
        }
    }

    const updateAppointment = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'update', formData, '', 'appointment'),
            ])
            const from = 'dashboard'
            if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('dashboard')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AppointmentContext.Provider
            value={{
                available_appointments: state.available_appointments,
                all_appointments_auction: state.all_appointments_auction,
                all_appointments_user: state.all_appointments_user,
                appointment_detail_user: state.appointment_detail_user,
                responseStatus: state.responseStatus,
                invoice_appointment: state.invoice_appointment,
                getAvailbleAppointments,
                getAllAppointmentsForAuction,
                getAllAppointmentsForUser,
                getAppointmentForInvoice,
                updateAppointment,
                clearResponse,
            }}
        >
            {props.children}
        </AppointmentContext.Provider>
    )
}

export default AppointmentState
