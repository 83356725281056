import { GET_VERSAPAY_LINK, RESPONSE_STATUS, CLEAR_RESPONSE } from './payTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_VERSAPAY_LINK:
            return {
                ...state,
                get_versapay_link: action.payload,
            }

        default:
            return state
    }
}
