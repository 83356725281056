import React, { useContext } from 'react'
// import { Link } from 'react-router-dom'
import Slider from 'react-slick'
// import ProductCommonContext from '../../../../product/context/common/commonContext'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import { Button } from '@material-ui/core'
import { useMediaQuery } from 'react-responsive'

const HomePageCarousel = () => {
    // const productCommonContext = useContext(ProductCommonContext)
    const customCommonContext = useContext(CustomCommonContext)

    // const { setSearchValue } = productCommonContext
    const isMobile = useMediaQuery({
        query: '(max-width: 480px)',
    })
    const { allHomeBanner } = customCommonContext
    const settings = {
        dots: true,
        arrows: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 6000,
        cssEase: 'linear',
    }

    return (
        <div className="heroCarousel">
            <Slider {...settings}>
                {allHomeBanner && allHomeBanner.length
                    ? allHomeBanner.map((data, index) =>
                          data.active === 1 ? (
                              <div className="homeBanner homeMarginBottom" key={`banner_${index}`}>
                                  {isMobile ? (
                                      <img
                                          className="bannerImg"
                                          src={`${global.site_url}/uploads/banner/${
                                              data.image_mobile ? data.image_mobile : data.image
                                          }`}
                                          alt={`banner_${index}`}
                                      />
                                  ) : (
                                      <img
                                          className="bannerImg"
                                          src={`${global.site_url}/uploads/banner/${data.image}`}
                                          alt={`banner_${index}`}
                                      />
                                  )}

                                  <div className="bannerContent">
                                      <h3>{data.description}</h3>
                                      <h1>{data.title}</h1>
                                      {data.link ? (
                                          <Button
                                              onClick={() => window.open(data.link, '_blank')}
                                              style={{
                                                  border: `1px solid ${data.color}`,
                                                  color: data.color,
                                              }}
                                          >
                                              {`${
                                                  data.button_label
                                                      ? data.button_label
                                                      : 'View Details'
                                              } `}
                                          </Button>
                                      ) : null}
                                  </div>
                              </div>
                          ) : null,
                      )
                    : null}
            </Slider>
        </div>
    )
}

export default HomePageCarousel
