import { Button, Divider, Fade, Tooltip } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import CustomInput from '@/product/components/atoms/Inputs/CustomInput'
import './ProductCard.css'
import FavouriteCheckbox from '@/product/components/atoms/FavoriteCheckbox'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import SecondaryButton from '@/product/components/atoms/SecondaryButton'
import Bidding from '@/custom/components/molecules/Bidding/BiddingItem'
import SealedBidding from '@/custom/components/molecules/Bidding/SealedItem'

import Buynow from '@/custom/components/molecules/Buynow/BuynowItem'
import {
    handleRedirectInternal,
    dateFormatFront,
    currencyFormat,
    floatFormat,
} from '@/custom/common/components'
import { checkProductOpen } from '@/product/common/components'
import CommonContext from '@/product/context/common/commonContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import AuthContext from '@/product/context/auth/authContext'
import Timer from '@/product/common/timer'
// import Timer from '@/custom/common/timer'
import { FALLBACK_IMAGE } from '@/utils'
import ReverseBidding from '@/custom/components/molecules/Reverse/Bidding/BiddingItem'

const Grid = (props) => {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { setBidHistoryValue } = commonContext
    const { user, isAuthenticated } = authContext
    const { configVariables } = customCommonContext

    const [product, setProduct] = useState()
    const [reload, setReload] = useState(true)
    const history = useHistory()
    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const dateAddedReached = (data) => {
        if (product.future_active) {
            if (data) {
                setProduct({ ...product, future_active: 0 })
            }
        }
    }

    const dateClosedReached = (data) => {
        if (product.past_inactive) {
            if (data) {
                setProduct({ ...product, past_inactive: 0 })
            }
        }
    }

    return (
        <Fade in={true} timeout={600}>
            <div className="productCardGrid">
                {product ? (
                    <div className={product.auctiontype === 'sealed' ? 'offerGrid' : ''}>
                        <div className="pcgImg">
                            <img
                                className="cursorDecoy"
                                onClick={props.drawerHandler}
                                src={`${global.site_url}/uploads/product/compressed/${product.avatar}`}
                                onError={(e) => FALLBACK_IMAGE(e)}
                                alt={product.title}
                                id="grid_productimage"
                            />
                            {isAuthenticated && (
                                <>
                                    {product.auctiontype !== 'sealed' ? (
                                        <>
                                            {product.bidtopstatus === 'outbid' && (
                                                <h4
                                                    className="productWinningStatus outbid"
                                                    id={
                                                        product.wprice < product.rprice
                                                            ? 'mybids_outbid_reservenotmet'
                                                            : 'mybids_outbid'
                                                    }
                                                >
                                                    {product.wprice < product.rprice
                                                        ? 'Outbid, Reserve not met'
                                                        : 'Outbid'}
                                                </h4>
                                            )}
                                            {product.bidtopstatus === 'winner' && (
                                                <h4
                                                    className="productWinningStatus winning"
                                                    id={
                                                        product.wprice < product.rprice
                                                            ? 'mybids_winning_reservenotmet'
                                                            : 'mybids_winning'
                                                    }
                                                >
                                                    {product.wprice < product.rprice
                                                        ? 'Winning, Reserve not met'
                                                        : 'Winning'}
                                                </h4>
                                            )}
                                            {product.bidtopstatus === 'won' && (
                                                <h4
                                                    id="mybids_won"
                                                    className="productWinningStatus won"
                                                >
                                                    {'Won'}
                                                </h4>
                                            )}
                                            {product.bidtopstatus === 'lost' && (
                                                <h4
                                                    id="mybids_lost"
                                                    className="productWinningStatus lost"
                                                >
                                                    {'Lost'}
                                                </h4>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {product.bid_or_not ? (
                                                <>
                                                    {product.market_status === 'relisted' ? (
                                                        <>
                                                            <h4
                                                                className="productWinningStatus outbid"
                                                                id={'offer_moved_to_auction'}
                                                            >
                                                                {'Moved to Auction'}
                                                            </h4>{' '}
                                                        </>
                                                    ) : product.market_status === 'open' ? (
                                                        <>
                                                            {product.past_inactive ? (
                                                                <>
                                                                    {/* <h4
                                                                    className="productWinningStatus winning"
                                                                    id={'offer_active'}
                                                                >
                                                                    {'Active'}
                                                                </h4> */}
                                                                </>
                                                            ) : (
                                                                <h4
                                                                    className="productWinningStatus outbid"
                                                                    id={'offer_pending'}
                                                                >
                                                                    {'Pending'}
                                                                </h4>
                                                            )}
                                                        </>
                                                    ) : null}
                                                    {product.market_status === 'sold' ? (
                                                        <>
                                                            {parseInt(product.buynowed, 10) ===
                                                            parseInt(user?.id, 10) ? (
                                                                <h4
                                                                    className="productWinningStatus won"
                                                                    id="offer_won"
                                                                >
                                                                    {'Won'}
                                                                </h4>
                                                            ) : null}
                                                            {parseInt(product.buynowed, 10) !==
                                                            parseInt(user?.id, 10) ? (
                                                                <h4
                                                                    className="productWinningStatus lost"
                                                                    id="offer_lost"
                                                                >
                                                                    {'Lost'}
                                                                </h4>
                                                            ) : null}{' '}
                                                        </>
                                                    ) : product.market_status === 'unsold' ? (
                                                        <>
                                                            <h4
                                                                className="productWinningStatus lost"
                                                                id="offer_lost"
                                                            >
                                                                {'Lost'}
                                                            </h4>
                                                        </>
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </>
                                    )}
                                    {/* {(product.bidtopstatus === 'outbid' ||
                                        product.bidtopstatus === 'winner') &&
                                        product.wprice <= product.rprice && (
                                            <>
                                                <Tooltip title="Reserve price not met">
                                                    <span className="material-icons reserveNotMet">
                                                        lock_open
                                                    </span>
                                                </Tooltip>
                                            </>
                                        )} */}
                                </>
                            )}
                        </div>

                        {product.market_status === 'open' ? (
                            <div className="gridTimer">
                                <p>
                                    {product.date_closed ? (
                                        <Timer
                                            date_added={product.date_added}
                                            date_closed={product.date_closed}
                                            withText={1}
                                            date_added_check={product.future_active}
                                            date_added_reached={dateAddedReached}
                                            date_closed_check={product.past_inactive}
                                            date_closed_reached={dateClosedReached}
                                            endText={'Time left' + ':'}
                                            startText={'Starts in' + ':'}
                                            closedText={
                                                product.auctiontype === 'sealed'
                                                    ? 'Offer Closed'
                                                    : product.market_status === 'open'
                                                    ? 'Auction Closing'
                                                    : 'Auction Closed'
                                            }
                                        ></Timer>
                                    ) : null}
                                </p>
                            </div>
                        ) : (
                            <div className="gridTimer d-flex justify-content-center align-items-center">
                                <h6 className="m-0">{'Closed on'}:</h6>
                                <p className="d-flex align-items-center">
                                    {dateFormatFront(product.date_closed)}
                                </p>
                            </div>
                        )}

                        <h2
                            className="gridProdTitle"
                            onClick={props.drawerHandler}
                            id="grid_producttitle"
                        >
                            {product.title}
                        </h2>
                        <div className="gridLotDetails">
                            <div className="gridMiscDet">
                                <h6>Lot number:</h6>
                                <p>{product?.auction_lot}</p>
                            </div>
                            <div className="gridMiscDet">
                                <h6>Total weight:</h6>
                                <p>
                                    {floatFormat(product?.weight)}&nbsp;
                                    {product?.weight > 1 ? 'cts' : 'ct'}
                                </p>
                            </div>
                            <div className="gridMiscDet">
                                <h6>Stone count:</h6>
                                {product?.total_stones !== 0 && product?.total_stones !== null ? (
                                    <p>{product?.total_stones}</p>
                                ) : (
                                    'N/A'
                                )}
                            </div>
                            <div className="gridMiscDet">
                                <h6>Avg size:</h6>
                                {product?.average_size !== 0 && product?.average_size !== null ? (
                                    <p>
                                        {floatFormat(product?.average_size)}&nbsp;
                                        {product?.average_size > 1 ? 'cts' : 'ct'}
                                    </p>
                                ) : (
                                    'N/A'
                                )}
                            </div>
                        </div>
                        {product?.custom_bid_data?.length ? (
                            <div className="gridMiscDet initOffer">
                                <h6>Your Initial Offer:</h6>

                                <p>
                                    {currencyFormat(product?.custom_bid_data[0]?.offer_bid)} per
                                    carat
                                </p>
                            </div>
                        ) : null}

                        {/* <div className="gridBidInfo d-flex justify-content-between align-items-center">
                            <h6>{t('auction')} id:</h6>
                            <p>
                                <span>{`#${product.auctionid}`}</span>
                            </p>
                        </div> */}

                        {product.auctiontype === 'sealed' ? null : <Divider />}
                        {isAuthenticated ? (
                            <>
                                {product.market_status === 'open' ? (
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                            {product.auction ? (
                                                <>
                                                    {product.auctiontype === 'sealed' ? (
                                                        <>
                                                            {product.current_bid ? (
                                                                <div className="currOffer activeBid">
                                                                    <h6>
                                                                        {product.bid_or_not
                                                                            ? 'My Current Offer'
                                                                            : 'Starting Price'}
                                                                        :
                                                                    </h6>
                                                                    <p>
                                                                        {currencyFormat(
                                                                            product.current_bid,
                                                                        )}{' '}
                                                                        per carat
                                                                    </p>
                                                                </div>
                                                            ) : null}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {product.cbidtext ==
                                                            'Starting Price' ? (
                                                                <>
                                                                    <h6>{product.cbidtext}: </h6>
                                                                    <p>
                                                                        {product?.hide_start_price
                                                                            ? 'Hidden'
                                                                            : `${currencyFormat(
                                                                                  product.wprice,
                                                                              )} per carat`}
                                                                    </p>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <h6>{product.cbidtext}: </h6>
                                                                    <p>
                                                                        {currencyFormat(
                                                                            product.wprice,
                                                                        )}{' '}
                                                                        per carat
                                                                    </p>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            ) : null}
                                        </div>
                                        {product.market_status === 'open' &&
                                        product.auctiontype !== 'sealed' ? (
                                            <FavouriteCheckbox
                                                watchlisted={product.watchlistid}
                                                project_id={product.id}
                                            />
                                        ) : null}
                                    </div>
                                ) : product.bid_or_not && product.buynowamount ? (
                                    <>
                                        {product.auctiontype === 'sealed' ? (
                                            <>
                                                {product.market_status === 'sold' ? (
                                                    <>
                                                        {parseInt(product.buynowed, 10) ===
                                                        parseInt(user?.id, 10) ? (
                                                            <>
                                                                <div className="gridBidInfo d-flex justify-content-between align-items-center mt-3">
                                                                    <h6>{product.cbidtext}: </h6>
                                                                    <p className="d-flex justify-content-between align-items-center">
                                                                        <span>
                                                                            {currencyFormat(
                                                                                product.buynowamount,
                                                                            )}{' '}
                                                                            per carat
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className="gridBidInfo d-flex justify-content-between align-items-center"></div>
                                                            </>
                                                        ) : null}{' '}
                                                    </>
                                                ) : null}
                                            </>
                                        ) : (
                                            <>
                                                <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                                    <h6>{product.cbidtext}: </h6>
                                                    <p className="d-flex justify-content-between align-items-center">
                                                        <span>
                                                            {currencyFormat(product.buynowamount)}{' '}
                                                            per carat
                                                        </span>
                                                    </p>
                                                </div>
                                                <div className="gridBidInfo d-flex justify-content-between align-items-center"></div>
                                            </>
                                        )}
                                    </>
                                ) : null}
                            </>
                        ) : (
                            ''
                        )}
                        {props.from === 'dashboard' &&
                        (props.action === 'won' || props.action === 'buynow') &&
                        product.buynowed &&
                        product.buynowed === user?.id ? (
                            <div className="actionAfterWin">
                                {parseInt(product.buynowpaid, 10) === 1 ? (
                                    <>
                                        <SecondaryButton
                                            label={'View invoice'}
                                            id="grid_viewinvoice"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        {/* <PrimaryButton
                                            label={'Make payment'}
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `checkout/invoice/${product.common_invoice}`,
                                                )
                                            }
                                        /> */}
                                    </>
                                )}
                            </div>
                        ) : product.market_status === 'open' && !product.future_active ? (
                            <>
                                <div
                                    className={`gridBidBox ${
                                        product.current_bid ? 'offerCurrBid' : ''
                                    }`}
                                >
                                    {product.auction ? (
                                        <>
                                            {product.auctiontype == 'reverse' ? (
                                                <ReverseBidding
                                                    data={product}
                                                    type={
                                                        parseInt(
                                                            configVariables?.proxy_bidding_settings,
                                                        ) === 0
                                                            ? 'hard'
                                                            : 'proxy'
                                                    }
                                                />
                                            ) : (
                                                <>
                                                    {isAuthenticated ? (
                                                        <>
                                                            {product.auctiontype === 'sealed' ? (
                                                                <SealedBidding
                                                                    data={product}
                                                                    type={'sealed'}
                                                                />
                                                            ) : (
                                                                <Bidding
                                                                    data={product}
                                                                    type={
                                                                        parseInt(
                                                                            configVariables?.proxy_bidding_settings,
                                                                        ) === 0
                                                                            ? 'hard'
                                                                            : 'proxy'
                                                                    }
                                                                />
                                                            )}
                                                        </>
                                                    ) : (
                                                        <PrimaryButton
                                                            label={'Login to bid'}
                                                            btnSize="small"
                                                            id="grid_logintobid"
                                                            onClick={() =>
                                                                handleRedirectInternal(
                                                                    history,
                                                                    'login',
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <Buynow
                                            data={product}
                                            editQuantity={true}
                                            isOnlyBuynow={product.auction === 0 ? true : false}
                                        />
                                    )}
                                </div>
                            </>
                        ) : product.buynowed && product.buynowed === user?.id ? (
                            <div className="actionAfterWin">
                                {parseInt(product.buynowpaid, 10) === 1 ? (
                                    <>
                                        <SecondaryButton
                                            label={'View invoice'}
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        {/* <PrimaryButton
                                            label={'Make payment'}
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `checkout/invoice/${product.common_invoice}`,
                                                )
                                            }
                                        /> */}
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="gridBidBox"></div>
                        )}
                        {product.market_status === 'open' && product.auctiontype === 'sealed' ? (
                            <div className="offerFavBox">
                                <FavouriteCheckbox
                                    watchlisted={product.watchlistid}
                                    project_id={product.id}
                                    addLabel="Add to watchlist"
                                    removeLabel="Remove from watchlist"
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        </Fade>
    )
}

export default Grid
