import {
    GET_ALL_STATIC_PAGES,
    GET_SINGLE_STATIC_PAGE,
    RESPONSE_STATUS,
    GET_ALL_TABLE_VALUES,
    CLEAR_RESPONSE,
    GET_ALL_BID_INCREMENTS,
    GET_SINGLE_BID_INCREMENT,
    SEARCH_TABLE,
} from './settingTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_STATIC_PAGES:
            return {
                ...state,
                configurations: action.payload,
            }
        case GET_SINGLE_STATIC_PAGE:
            return {
                ...state,
                single_configuration: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_ALL_BID_INCREMENTS:
            return {
                ...state,
                bid_increments: action.payload,
            }
        case GET_SINGLE_BID_INCREMENT:
            return {
                ...state,
                single_bid_increment: action.payload,
            }
        case SEARCH_TABLE:
            return {
                ...state,
                search_table_data: action.payload,
            }
        case GET_ALL_TABLE_VALUES:
            return {
                ...state,
                table_data: action.payload,
            }
        default:
            return state
    }
}
