import {
    GET_ALL_SEARCH,
    GET_ALL_PAST_SEARCH,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_DASHBOARD,
} from './auctionTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_SEARCH:
            return {
                ...state,
                search_allauctions: action.payload,
            }
        case GET_ALL_PAST_SEARCH:
            return {
                ...state,
                search_all_pastauction: action.payload,
            }
        case GET_ALL_DASHBOARD:
            return {
                ...state,
                search_alldashboardauctions: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }

        default:
            return state
    }
}
