import React from 'react'
import { Helmet } from 'react-helmet'
import { SITE_NAME } from './index.js'

const SEO = ({ title, description, keywords, url, image }) => {
    return (
        <Helmet>
            <title>{`${title ? title + ' | ' : ''} ${SITE_NAME}`}</title>
            <meta name="title" content={`${title ? title + ' | ' : ''} ${SITE_NAME}`} />
            <meta name="description" content={description}></meta>
            <meta name="keywords" content={keywords}></meta>

            <meta property="og:url" content={url} />
            <meta property="og:title" content={`${title ? title + ' | ' : ''} ${SITE_NAME}`} />
            <meta property="og:description" content={description} />
            {/* <meta
                property="og:image"
                content={
                    image ? image : `${window.location.origin}/assets/images/short.png`
                }
            />
            <meta
                property="og:image:secure_url"
                content={
                    image ? image : 
                }
            /> */}
        </Helmet>
    )
}

export default SEO
