import React, { useReducer } from 'react'
import ShipengineContext from './shipengineContext'
import ShipengineReducer from './shipengineReducer'
import { apiCall } from '../../../common/api'
import { response } from '../../common'

import {
    SHIPPING_QUOTE,
    SHIPPING_SAMPLE_QUOTE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './shipengineTypes'

const ShipengineState = (props) => {
    const initialState = {
        responseStatus: null,
        shipping_quotes: [],
        shipping_sample_quotes: [],
    }

    const [state, dispatch] = useReducer(ShipengineReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const shippingInitiate = async (formData) => {
        const from = 'getQuoteRequest'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'shipping/shipengine'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SHIPPING_QUOTE,
                    payload: {
                        shipping_quotes: res.data.data.responseData,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const getSampleQuoteRequest = async (formData) => {
        const from = 'getSampleQuoteRequest'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'shipping/shipengine'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: SHIPPING_SAMPLE_QUOTE,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const confirmShippingQuote = async (formData) => {
        const from = 'confirmShippingQuote'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'shipping/shipengine'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const validateAddress = async (formData) => {
        const from = 'validateAddress'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'validateAddress', formData, '', 'shipping/shipengine'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <ShipengineContext.Provider
            value={{
                responseStatus: state.responseStatus,
                shipping_quotes: state.shipping_quotes,
                shipping_sample_quotes: state.shipping_sample_quotes,
                shippingInitiate,
                confirmShippingQuote,
                validateAddress,
                clearResponse,
                getSampleQuoteRequest,
            }}
        >
            {props.children}
        </ShipengineContext.Provider>
    )
}
export default ShipengineState
