import React, { useReducer } from 'react'
import axios from 'axios'
import CustomerContext from './customerContext'
import CustomerReducer from './customerReducer'
import { DirectAPICAll } from './../../../common/components'
import { response } from '../../common'
import { GET_CUSTOMER_DETAILS, RESPONSE_STATUS, CLEAR_RESPONSE } from './customerTypes'

const StripeCustomerState = (props) => {
    const initialState = {
        customer_details: null,
        responseStatus: null,
    }
    const [state, dispatch] = useReducer(CustomerReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    //Skyflow
    const createStripeCustomer = async (formData, noAlert) => {
        const from = 'create'
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/create`,
                formData,
            )
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from, noAlert)
        }
    }
    const getStripeCustomer = async (formData, noAlert) => {
        const from = 'retrieve'
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/retrieve`,
                formData,
            )
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_CUSTOMER_DETAILS,
                    payload: res.data.data.responseData.record,
                })
            }
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from)
        }
    }
    const updateStripeCustomer = async (formData, noAlert) => {
        const from = 'update'
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/update`,
                formData,
            )
            resp.commonResponse(res.data, from)
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from)
        }
    }

    //Custom
    const createCustomStripeCustomer = async (formData, noAlert) => {
        const from = 'CUSTOM_CREATE'
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/stripe/customer/create`,
                formData,
            )
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from, noAlert)
        }
    }
    const getCustomStripeCustomer = async (formData, noAlert) => {
        const from = 'CUSTOM_RETRIEVE'
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/stripe/customer/retrieve`,
                formData,
            )
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_CUSTOMER_DETAILS,
                    payload: res.data.data.responseData.record,
                })
            }
        } catch (err) {
            console.log('errr', err, noAlert)
            resp.commonErrorResponse(from, noAlert)
        }
    }
    const updateCutomStripeCustomer = async (formData, noAlert) => {
        const from = 'CUSTOM_UPDATE'
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/stripe/customer/update`,
                formData,
            )
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CustomerContext.Provider
            value={{
                responseStatus: state.responseStatus,
                customer_details: state.customer_details,
                updateStripeCustomer,
                createStripeCustomer,
                getStripeCustomer,
                updateCutomStripeCustomer,
                getCustomStripeCustomer,
                createCustomStripeCustomer,
                clearResponse,
            }}
        >
            {props.children}
        </CustomerContext.Provider>
    )
}

export default StripeCustomerState
