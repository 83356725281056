import React, { useState, useContext, useEffect, useRef } from 'react'
import openSocket from 'socket.io-client'
import FilterPanel from '@/custom/components/organisms/FilterPanel'
import GridView from '@/custom/components/molecules/ProductCard/GridView'
import './Search.css'
import CustomSelect from '@/product/components/atoms/Inputs/CustomSelect'
import { Button, Fade } from '@material-ui/core'

import ListView from '@/custom/components/molecules/ProductCard/ListView'
import Drawer from '@material-ui/core/Drawer'
import ProductView from '@/custom/components/organisms/ProductView'
import ProductContext from '@/product/context/product/productContext'
import AuthContext from '@/product/context/auth/authContext'
import AlertContext from '@/product/context/alert/alertContext'
import CommonContext from '@/product/context/common/commonContext'
import CustomCommonContext from '@/custom/context/common/commonContext'
import { Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { messageHandler } from '@/product/common/socketHandler'
import { useCustomMediaQuery } from '@/product/common/components'
import NoRecordsFound from '@/custom/components/atoms/NoRecordsFound'
import Loaders from '@/custom/components/molecules/Loaders'
import { useFormik } from 'formik'

import SEO from '@/utils/SEO'
const useStyles = makeStyles({
    fullList: {
        width: 'auto',
    },
})

const SearchComp = (props) => {
    const productContext = useContext(ProductContext)

    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)

    const customCommonContext = useContext(CustomCommonContext)

    const commonContext = useContext(CommonContext)
    const { setSearchValue, searchValue, clearSearchValue } = commonContext
    const { user, isAuthenticated, saveSearch } = authContext
    const [loading, setLoading] = useState(false)

    const [feature, setFeature] = useState({})
    const [isLoading, setIsLoading] = useState(true)

    const classes = useStyles()

    const { setSocketModal } = customCommonContext

    const { search_allproducts, getAllSearchProducts, responseStatus, addSavedSearch } =
        productContext

    const { setAlert } = alertContext

    const auctionView = props.auctionView
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const formikSearch = props.formikSearch
    const [viewProduct, setViewProduct] = useState([])
    const clearSearchFilter = () => {
        formikSearch.values = props.initialValues
    }
    const formik = useFormik({
        initialValues: props.formik,
    })

    // Used to set initail search values
    /*const [search, setSearch] = useState(
        props.search
            ? props.search.data
            : 
    )*/

    useEffect(() => {
        if (searchValue) {
            const searchFilter = searchValue.filters
            Object.keys(searchFilter).forEach((key) => {
                if (
                    searchFilter[`${key}`].type === 'array' ||
                    searchFilter[`${key}`].type === 'findinarray'
                ) {
                    let value = searchFilter[`${key}`].value
                    formikSearch.setFieldValue(`filters.${key}.value`, [value.toString()])
                } else {
                    let value = searchFilter[`${key}`].value
                    formikSearch.setFieldValue(`filters.${key}.value`, value.toString())
                }
            })

            clearSearchValue()
        }
    }, [searchValue])

    // Used to change page
    const onHandlePage = (event, value) => {
        formikSearch.setFieldValue(`page`, value)
    }

    useEffect(() => {
        setIsLoading(false)
        setViewProduct(search_allproducts.records.length > 0 ? search_allproducts.records : [])
    }, [search_allproducts])

    // useEffect(() => {
    //     setViewProduct(search_allproducts.records.length > 0 ? search_allproducts.records : [])
    //     props.getSearch ? props.getSearch(search_allproducts.records) : []
    //     setTimeout(() => {
    //         setIsLoading(false)
    //     }, 2000)
    // }, [search_allproducts])

    // useEffect(() => {
    //     if (searchValue?.filters?.searchbar?.value) {
    //         formikSearch.setFieldValue(
    //             `filters.searchbar.value`,
    //             searchValue.filters.searchbar.value,
    //         )
    //         setSavedSearchTemp(searchValue.filters.searchbar.value)
    //         setSavedSearchOn(true)
    //     }
    //     setViewProduct([])
    //     return () => {
    //         setViewProduct([])
    //     }
    // }, [])

    // useEffect(() => {
    //     setIsLoading(true)
    //     if (!searchValue) {
    //         if (
    //             !formikSearch.values.filters.searchbar.value ||
    //             formikSearch.values.filters.searchbar.value == ''
    //         )
    //             formikSearch.setFieldValue(`filters.searchbar.value`, savedSearchTemp)
    //         getAllSearchProducts(formikSearch.values, 'liveProducts')
    //     } else {
    //         //getAllSearchProducts(search)
    //     }
    // }, [formikSearch.values])
    const viewProductRef = useRef(viewProduct)
    const userRef = useRef(user)

    // Sets product and user refrences on page load
    useEffect(() => {
        viewProductRef.current = viewProduct
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setViewProduct,
            type,
        )
    }
    // Used to display items in wishlist on page load
    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'watchlist') {
                handler(
                    {
                        id: responseStatus.data.project_id,
                        status: responseStatus.data.status,
                    },
                    'watchlistAdded',
                )
            }
        }
    }, [responseStatus])

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, { transports: ['websocket'] })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        socket.on('disconnect', (data) => {
            console.log('data Server', data)
            if (data !== 'io client disconnect') {
                setSocketModal()
            }
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])

    useEffect(() => {
        if (state.right) {
            const index = viewProduct.findIndex((s) => s.id == parseInt(state.data.id, 10))
            if (index !== -1) {
                setState({ ...state, data: viewProduct[index] })
            }
        }
    }, [viewProduct])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }
    // Clears search filter values
    //clearSearchFilter(formikSearch)

    return (
        // <div className="search customContainer">
        //     <div className="w-100 searchHead d-flex justify-content-between align-items-center flex-wrap">
        //         <Button
        //             className="toggleRespDrawer"
        //             variant="outlined"
        //             onClick={toggleDrawer('bottom', true)}
        //         >
        //             <span className="material-icons">tune</span>
        //             {t('filters')}
        //         </Button>
        //     </div>
        //     <div></div>
        //     <div className="searchMiscFilters w-100 mb-3 d-flex justify-content-end align-items-end">
        //         <div className="gridListToggle">
        //             <Button
        //                 className={auctionView === 'Grid' ? 'active' : ''}
        //                 onClick={() => setAuctionView('Grid')}
        //             >
        //                 <span className="material-icons">apps</span>
        //             </Button>
        //             <Button
        //                 className={auctionView === 'List' ? 'active' : ''}
        //                 onClick={() => setAuctionView('List')}
        //             >
        //                 <span className="material-icons">view_list</span>
        //             </Button>
        //         </div>

        //         <CustomSelect
        //             label={t('results_per_page')}
        //             name="resultsPerPage"
        //             selectType="noBorder"
        //             value={formikSearch.values.limit}
        //             size="small"
        //             onChange={(event, editor) => {
        //                 formikSearch.setFieldValue('limit', event.target.value)
        //             }}
        //         >
        //             {searchData.pageOptions.map((opt, optindex) => (
        //                 <option value={opt.value}>{opt.show}</option>
        //             ))}
        //         </CustomSelect>

        //         <CustomSelect
        //             label={t('sort_by')}
        //             value={formikSearch.values.orderby}
        //             size="small"
        //             selectType="noBorder"
        //             name="orderby"
        //             // shrink={search.orderby !== '' ? true : false}
        //             onChange={(event, editor) => {
        //                 formikSearch.setFieldValue('orderby', event.target.value)
        //             }}
        //         >
        //             {searchData.sortShow.map((opt, optindex) => (
        //                 <option value={opt.value}>{opt.show}</option>
        //             ))}
        //         </CustomSelect>
        //     </div>
        //     <div className="searchCnt d-flex justify-content-start align-items-start">
        //         {props.fill}
        //         {console.log(state.data, 'state.datas')}
        <>
            <SEO title="Search" url={window.location.href} />
            <div className="searchRt">
                <div className="d-flex justify-content-between align-items-center"></div>
                {isLoading ? (
                    <Loaders name="product_grid_view" isLoading={isLoading} loop={6} />
                ) : viewProduct.length > 0 ? (
                    <>
                        <div className={`searchResults ${auctionView}`}>
                            <Drawer
                                className="rightDrawer"
                                anchor={'right'}
                                open={state['right']}
                                onClose={toggleDrawer('right', false)}
                            >
                                <ProductView
                                    data={state.data}
                                    drawerHandler={toggleDrawer('right', false)}
                                />
                            </Drawer>

                            {viewProduct.map((data, index) => (
                                <>
                                    {auctionView === 'Grid' ? (
                                        <>
                                            <GridView
                                                data={data}
                                                favId={`searchProd_${index}`}
                                                drawerHandler={toggleDrawer('right', true, data)}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <ListView
                                                data={data}
                                                favId={`searchProd_${index}`}
                                                drawerHandler={toggleDrawer('right', true, data)}
                                            />
                                        </>
                                    )}
                                </>
                            ))}
                        </div>

                        <div className="d-flex justify-content-between align-items-center flex-wrap w-100 my-3 pagination-wrapper">
                            <h6>
                                {'Showing'} {search_allproducts.setDisp} {'of'}{' '}
                                {search_allproducts.totalRecords}
                            </h6>
                            {props.pagination ? (
                                <Pagination
                                    count={Math.ceil(
                                        search_allproducts.totalRecords / formikSearch.values.limit,
                                    )}
                                    page={formikSearch.values.page}
                                    onChange={onHandlePage}
                                    siblingCount={3}
                                    showFirstButton
                                    showLastButton
                                    boundaryCount={2}
                                />
                            ) : null}
                        </div>
                    </>
                ) : (
                    <NoRecordsFound />
                )}
            </div>
        </>
        //     </div>
        // </div>
    )
}
export default SearchComp
