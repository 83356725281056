import React, { useState, useContext, useEffect } from 'react'
import CustomCommonContext from '@/custom/context/common/commonContext'
import DynamicContext from '@/product/context/dynamic/dynamicContext'
import { dateFormatFront, currencyFormat } from '@/custom/common/components'
// import TertiaryButton from '@/custom/components/atoms/TertiaryButton'

import SEO from '@/utils/SEO'
import { indexOf } from 'lodash'

const DynamicView = (props) => {
    const [arrayValue, setArrayValue] = useState([])
    const [product, setProduct] = useState([])
    const [dynamicArray, setDynamicArray] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const dynamicContext = useContext(DynamicContext)
    const customCommonContext = useContext(CustomCommonContext)
    const { alldata_all_dropdown } = customCommonContext

    const { getAllDynamicArray, dynamicValue } = dynamicContext
    useEffect(() => {
        setProduct(props.product)
    }, [props.product])

    useEffect(() => {
        props.updateLoading(true)
        getAllDynamicArray(
            {
                page: 1,
                limit: 20,
                order: 'asc',
                orderby: 'df.show_order',
                table: 'dynamic_fields',
                filters: {
                    action: {
                        value: 1,
                        type: 'in',
                        field: 'df.view_page',
                    },
                    active: {
                        value: 1,
                        type: 'in',
                        field: 'df.active',
                    },
                },
            },
            'listingEditDynamic',
        )
    }, [])

    useEffect(() => {
        if (dynamicValue && dynamicValue.from === 'listingEditDynamic') {
            if (dynamicValue.records.length) {
                setDynamicArray(dynamicValue.records)
                props.updateLoading(false)
            }
        }
    }, [dynamicValue])

    useEffect(() => {
        let ignoreArray = []
        if (props.ignoreArray) {
            ignoreArray = props.ignoreArray
        }
        if (dynamicArray.length) {
            let data = dynamicArray.filter(
                (data) =>
                    data.type != 'imagewithurl' &&
                    data.type != 'misc' &&
                    data.is_default === 0 &&
                    !ignoreArray.includes(data.name),
            )
            setArrayValue(data)
        }
    }, [dynamicArray, props.ignoreArray])
    // useEffect(() => {
    //     if (arrayValue.length) {
    //         props.updateLoading(false)
    //     }
    // }, [arrayValue])

    console.log(arrayValue, 'array')

    console.log(arrayValue, 'array')

    return (
        <>
            {arrayValue.map((data, index) => {
                return data.type === 'select' ? (
                    <div className="pvDescChild">
                        <label>{data.label}:</label>

                        {/* {console.log(alldata_all_dropdown, 'datass')} */}

                        <h6>
                            {alldata_all_dropdown
                                .filter(
                                    (values) =>
                                        parseInt(values.id, 10) ===
                                        parseInt(product[data.name], 10),
                                )
                                .map((values) => values.description)}
                        </h6>
                    </div>
                ) : data.type === 'checkbox' ? (
                    <div className="pvDescChild">
                        <label>{data.label}:</label>
                        {product[data.name] ? <h6>{'Yes'}</h6> : <h6>{'No'}</h6>}
                    </div>
                ) : data.type === 'ckeditor' ? (
                    <div className="pvDescChild">
                        <label>{data.label}:</label>
                        <h6
                            dangerouslySetInnerHTML={{
                                __html: product[data.name],
                            }}
                        ></h6>
                    </div>
                ) : data.type === 'ckeditor5' ? (
                    <div className="pvDescChild">
                        <label>{data.label}:</label>
                        <h6
                            dangerouslySetInnerHTML={{
                                __html: product[data.name],
                            }}
                        ></h6>
                    </div>
                ) : data.type === 'datetime' ? (
                    <div className="pvDescChild">
                        <label>{data.label}</label>
                        <h6>{dateFormatFront(product[data.name])}</h6>
                    </div>
                ) : data.type === 'switch' ? (
                    <div className="pvDescChild">
                        <label>{data.label}:</label>
                        {product[data.name] === 1 ? <h6>Yes</h6> : <h6>No</h6>}
                    </div>
                ) : data.type === 'radio' ? (
                    <div className="pvDescChild">
                        <label>{data.label}:</label>

                        {/* {console.log(alldata_all_dropdown, 'datass')} */}

                        <h6>
                            {product[data.name]?.split(',')?.map((data, index) => (
                                <>
                                    {
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex((ele) => ele.id == data)
                                        ]?.description
                                    }
                                </>
                            ))}
                        </h6>
                    </div>
                ) : data.type === 'uploadDropZone' && data.accept.includes('image') ? (
                    <div className="pvDescChild">
                        <label>{data.label}:</label>

                        <img
                            src={global.site_url + `/uploads/${data.folder}/` + product[data.name]}
                        />
                    </div>
                ) : data.type === 'checkboxarray' ? (
                    <div className="pvDescChild">
                        <label>{data.label}:</label>

                        {/* {console.log(alldata_all_dropdown, 'datass')} */}

                        <h6>
                            {product[data.name]?.split(',')?.map((data, index) => (
                                <>
                                    {
                                        alldata_all_dropdown[
                                            alldata_all_dropdown.findIndex((ele) => ele.id == data)
                                        ]?.description
                                    }
                                </>
                            ))}
                            {/* {alldata_all_dropdown.map((data, index) => {
                                let val = ''
                                if (data.id === parseInt(product[data.name], 10)) {
                                    val = indexOf(data)
                                }
                                return console.log(val, 'ghghg')
                            })} */}
                        </h6>
                    </div>
                ) : (
                    <div className="pvDescChild">
                        <label>{data.label}:</label>
                        {product[data.name] ? <h6>{product[data.name]}</h6> : ''}
                    </div>
                )
            })}
        </>
    )
}
export default DynamicView
