import {
    COMMON_VALUES,
    SOCKET_MODAL,
    SOCKET_CONNECTED,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    SWITCH_LANGUAGE,
} from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case COMMON_VALUES:
            return {
                ...state,
                ...action.payload,
            }
        case SWITCH_LANGUAGE:
            return {
                ...state,
                currentLanguage: action.payload,
            }
        case SOCKET_MODAL:
            return {
                ...state,
                socketModal: !state.socketModal,
            }
        case SOCKET_CONNECTED:
            return {
                ...state,
                socketConnected: !state.socketConnected,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
