import {
    GET_ALL_PRODUCTS,
    GET_ALL_ACTIVE_AUCTIONS,
    GET_SINGLE_PROJECT,
    GET_ALL_BID_HISTORY,
    GET_ALL_TTWBID_HISTORY,
    GET_SELLER_DASHBOARD_DATA,
    GET_ALL_PROJECT_ACCESS,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './productTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                all_products: action.payload,
            }
        case GET_ALL_ACTIVE_AUCTIONS:
            return {
                ...state,
                activelots: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_SINGLE_PROJECT:
            return {
                ...state,
                single_project: action.payload,
            }
        case GET_ALL_BID_HISTORY:
            return {
                ...state,
                search_allbidhistory: action.payload,
            }
        case GET_ALL_TTWBID_HISTORY:
            return {
                ...state,
                search_allttwbidhistory: action.payload,
            }
        case GET_SELLER_DASHBOARD_DATA:
            return {
                ...state,
                sellerDashboardData: action.payload,
            }
        case GET_ALL_PROJECT_ACCESS:
            return {
                ...state,
                all_project_access_req: action.payload,
            }
        default:
            return state
    }
}
