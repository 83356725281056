import React, { useReducer } from 'react'
import axios from 'axios'
import CardContext from './cardContext'
import CardReducer from './cardReducer'
import { response } from '../../common'
import { DirectAPICAll } from './../../../common/components'

import {
    GET_ALL_CARD_DETAILS,
    GET_CARD_DETAILS,
    GET_ALL_PAYMENT_METHOD_CARD_DETAILS,
    GET_PAYMENT_METHOD_CARD_DETAILS,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './cardTypes'

const StripeCardState = (props) => {
    const initialState = {
        get_all_card_details: null,
        get_card_details: null,
        get_all_payment_method_card_details: null,
        get_payment_method_card_details: null,
        responseStatus: null,
    }
    const [state, dispatch] = useReducer(CardReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    //Skyflow
    const createStripeCard = async (formData) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/source/create`,
                formData,
            )

            resp.commonResponse(res.data, 'card_source_create')
        } catch (err) {
            resp.commonErrorResponse('card_source_create')
        }
    }
    const createStripeCardWithCustomer = async (formData) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/create`,
                formData,
            )
            resp.commonResponse(res.data, 'card_source_create_customer_card')
        } catch (err) {
            resp.commonErrorResponse('card_source_create_customer_card')
        }
    }
    const getAllStripeCards = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/source/list`,
                data,
            )
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CARD_DETAILS,
                    payload: {
                        records: res.data.data.responseData.data.length
                            ? res.data.data.responseData.data
                            : [],
                        has_more: res.data.data.responseData.has_more,
                    },
                })
            } else {
                resp.commonResponse(res, 'card_source_list')
            }
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_list')
        }
    }
    const getSingleStripeCard = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/source/retrieve`,

                data,
            )
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_CARD_DETAILS,
                    payload: {
                        record: res.data.data.responseData,
                    },
                })
            } else {
                resp.commonResponse(res, 'card_details_response')
            }
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_details_response')
        }
    }
    const updateStripeCard = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/source/update`,
                data,
            )
            resp.commonResponse(res.data, 'card_source_update')
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_update')
        }
    }
    const deleteStripeCard = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/source/delete`,
                data,
            )
            resp.commonResponse(res.data, 'card_source_delete')
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_delete')
        }
    }

    //custom
    const createCustomStripeCard = async (formData) => {
        const from = 'CARD_CUSTOM_SOURCE_CREATE'

        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/stripe/customer/source/create`,
                formData,
            )

            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const createCustomStripeCardWithCustomer = async (formData) => {
        const from = 'CARD_CUSTOM_SOURCE_CREATE_CARD'
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/stripe/customer/create`,
                formData,
            )
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }
    const getAllCustomStripeCards = async (data) => {
        const from = 'CARD_CUSTOM_SOURCE_LIST'
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/stripe/customer/source/list`,
                data,
            )
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_CARD_DETAILS,
                    payload: {
                        records: res.data.data.responseData.data.length
                            ? res.data.data.responseData.data
                            : [],
                        has_more: res.data.data.responseData.has_more,
                    },
                })
            } else {
                resp.commonResponse(res, from)
            }
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from)
        }
    }
    const getSingleCustomStripeCard = async (data) => {
        const from = 'CARD_CUSTOM_SOURCE_RESPONSE'
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/stripe/customer/source/retrieve`,

                data,
            )
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_CARD_DETAILS,
                    payload: {
                        record: res.data.data.responseData,
                    },
                })
            } else {
                resp.commonResponse(res, from)
            }
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from)
        }
    }
    const updateCustomStripeCard = async (data) => {
        const from = 'CARD_CUSTOM_SOURCE_UPDATE'
        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/stripe/customer/source/update`,
                data,
            )
            resp.commonResponse(res.data, from)
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from)
        }
    }
    const deleteCustomStripeCard = async (data) => {
        const from = 'CARD_CUSTOM_SOURCE_DELETE'

        try {
            const res = await DirectAPICAll(
                'post',
                `${process.env.REACT_APP_DOMAIN}/api/paymentThird/stripe/customer/source/delete`,
                data,
            )
            resp.commonResponse(res.data, from)
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from)
        }
    }

    const validateCardSkyflow = async (data) => {
        const from = 'validate_card_skyflow'

        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/skyflow/createPaymentMethod`,
                data,
            )
            resp.commonResponse(res.data, from)
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse(from)
        }
    }

    const deletePaymentMethod = async (data, noAlert) => {
        const from = 'delete_payment_method'

        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/skyflow/deletePaymentMethod`,
                data,
            )
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const updatePaymentMethod = async (data, noAlert) => {
        const from = 'delete_payment_method'

        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/skyflow/updatePaymentMethod`,
                data,
            )
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    //Payment Method
    const createStripePaymentMethodCard = async (formData) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/payment_method/create`,
                formData,
            )

            resp.commonResponse(res.data, 'card_source_create')
        } catch (err) {
            resp.commonErrorResponse('card_source_create')
        }
    }
    const createStripePaymentMethodCardWithCustomer = async (formData) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/create`,
                formData,
            )
            resp.commonResponse(res.data, 'card_source_create_customer_card')
        } catch (err) {
            resp.commonErrorResponse('card_source_create_customer_card')
        }
    }
    const getAllStripePaymentMethodCards = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/payment_method/list`,
                data,
            )
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PAYMENT_METHOD_CARD_DETAILS,
                    payload: {
                        records: res.data.data.responseData.data.length
                            ? res.data.data.responseData.data
                            : [],
                        has_more: res.data.data.responseData.has_more,
                    },
                })
            } else {
                resp.commonResponse(res, 'card_source_list')
            }
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_list')
        }
    }
    const getSingleStripePaymentMethodCard = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/payment_method/retrieve`,

                data,
            )
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_PAYMENT_METHOD_CARD_DETAILS,
                    payload: {
                        record: res.data.data.responseData,
                    },
                })
            } else {
                resp.commonResponse(res, 'card_details_response')
            }
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_details_response')
        }
    }
    const updateStripePaymentMethodCard = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/payment_method/update`,
                data,
            )
            resp.commonResponse(res.data, 'card_source_update')
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_update')
        }
    }
    const deleteStripePaymentMethodCard = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/payment_method/delete`,
                data,
            )
            resp.commonResponse(res.data, 'card_source_delete')
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_delete')
        }
    }

    const defaultStripePaymentMethodCard = async (data) => {
        try {
            const res = await DirectAPICAll(
                'post',
                `${
                    process.env.REACT_APP_STRIPE_URL || 'https://paymentapi.auctionsoftware.com'
                }/api/customer/payment_method/default`,
                data,
            )
            resp.commonResponse(res.data, 'card_source_default')
        } catch (err) {
            console.log('errr', err)
            resp.commonErrorResponse('card_source_default')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CardContext.Provider
            value={{
                responseStatus: state.responseStatus,
                get_all_card_details: state.get_all_card_details,
                get_card_details: state.get_card_details,
                get_all_payment_method_card_details: state.get_all_payment_method_card_details,
                get_payment_method_card_details: state.get_payment_method_card_details,
                createStripeCard,
                createStripeCardWithCustomer,
                getAllStripeCards,
                getSingleStripeCard,
                updateStripeCard,
                deleteStripeCard,
                deleteCustomStripeCard,
                updateCustomStripeCard,
                getSingleCustomStripeCard,
                getAllCustomStripeCards,
                createCustomStripeCardWithCustomer,
                createCustomStripeCard,
                validateCardSkyflow,
                deletePaymentMethod,
                updatePaymentMethod,
                createStripePaymentMethodCard,
                createStripePaymentMethodCardWithCustomer,
                getAllStripePaymentMethodCards,
                getSingleStripePaymentMethodCard,
                updateStripePaymentMethodCard,
                deleteStripePaymentMethodCard,
                defaultStripePaymentMethodCard,
                clearResponse,
            }}
        >
            {props.children}
        </CardContext.Provider>
    )
}

export default StripeCardState
