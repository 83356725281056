import React, { useContext, useEffect, useState } from 'react'
import { handleRedirectInternal } from '@/custom/common/components'
import PrimaryButton from '@/product/components/atoms/PrimaryButton'
import CustomInput from '@/product/components/atoms/Inputs/CustomInput'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import authContext from '@/product/context/auth/authContext'
import TertiaryButton from '@/custom/components/atoms/TertiaryButton'
import CustomDialog from '@/custom/components/organisms/Dialog'
import { Button } from '@material-ui/core'
import * as Yup from 'yup'
import { mapData } from '@/product/common/components'
import BuynowContext from '@/product/context/buynow/buynowContext'
import { useTranslation } from 'react-i18next'
import SEO from '@/utils/SEO'

const Buynow = (props) => {
    const { isAuthenticated } = useContext(authContext)
    const { addToCart, responseStatus, clearResponse } = useContext(BuynowContext)
    const [product, setProduct] = useState()
    const { t } = useTranslation()

    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (product) {
            formik.setFieldValue('product_id', product.id)
        }
    }, [product])

    const validationArray = Yup.object({
        product_id: Yup.number().required('Product id required'),
        qty: Yup.number().required('Enter quantity'),
    })

    const formik = useFormik({
        initialValues: { product_id: 0, qty: 0 },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (toggleDialog) {
                let cart = []
                cart.push(values)
                addToCart({ cart })
            } else {
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'addToCart') {
                if (responseStatus.status === 'success') {
                    setToggleDialog(false)
                    setTimeout(() => {
                        handleRedirectInternal(history, 'buynowcart')
                    }, 2000)
                    clearResponse()
                }
            }
        }
    }, [responseStatus])

    // const buynowQuantity = [
    //     {
    //         label: 'enter_quantity',
    //         name: 'qty',
    //         type: 'number',
    //         placeholder: `${'max_quantity'} ${product ? product.qty - product.sold : 0}`,
    //         class: '',
    //         size: 'small',
    //         autoFocus: false,
    //         formik: formik,
    //     },
    // ]

    useEffect(() => {
        if (product) {
            formik.values.qty = product.qty - product.sold
        }
    }, [product])

    return (
        <>
            <SEO title="Auction view" url={window.location.href} />
            {product ? (
                <>
                    {product.market_status === 'open' && product.wprice < product.bprice ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt beforeLogin d-flex justify-content-between align-items-center">
                                {/* {props.editQuantity && Object.values(mapData(buynowQuantity))} */}
                                {isAuthenticated ? (
                                    <PrimaryButton
                                        label={t('Buy now')}
                                        type="submit"
                                        btnSize="small"
                                    />
                                ) : (
                                    <PrimaryButton
                                        label={t('Login to buy')}
                                        btnSize="small"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                )}
                            </div>
                        </form>
                    ) : null}
                </>
            ) : null}
            <CustomDialog
                title={t('Confirm buy now')}
                open={toggleDialog}
                function={changeDialogStatus}
            >
                <h5>{t('You are about to buy this product')}</h5>
                <h5>{t('Please confirm if you want to continue with this action')}</h5>
                <div className="actionWrapper">
                    <Button onClick={() => setToggleDialog(false)}>{t('Cancel')}</Button>
                    <PrimaryButton
                        onClick={formik.handleSubmit}
                        type="submit"
                        label={t('Submit')}
                    />
                </div>
            </CustomDialog>
        </>
    )
}

export default Buynow
